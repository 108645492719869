<template>
  <el-row class="table">
    <!-- <span class="title">Pygame预置代码管理</span> -->
    <div class="row-bg">
      <el-input
        clearable
        class="tb-sm-input"
        v-model.trim="queryData.title"
        placeholder="请输入名称"
        @keyup.enter.native="search"
      >
      </el-input>
      <el-button
        type="primary"
        size="medium"
        @click="search()"
        class="tb-button"
        >查 询</el-button
      >
      <el-button type="primary" size="medium" @click="reset()" class="tb-button"
        >重 置</el-button
      >
      <el-button
        type="info"
        size="medium"
        @click="addPygame({})"
        class="tb-button"
        >新 增</el-button
      >
    </div>
    <el-row>
      <el-col :xs="12" :sm="18" :md="24" :lg="24" :xl="24">
        <el-table
          class="tb-list"
          style="width: 100%; margin-bottom: 20px"
          border
          :data="dataList"
        >
          <el-table-column
            label="名称"
            show-overflow-tooltip
            align="center"
            prop=""
          >
            <template slot-scope="scope">
              {{ scope.row.title }}
            </template>
          </el-table-column>
          <el-table-column
            label="描述"
            align="center"
            prop="description"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="创建人"
            align="center"
            prop="name"
          ></el-table-column>
          <el-table-column label="创建时间" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime | dateFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <!-- <el-button
                type="primary"
                size="small"
                style="margin-right: 10px"
                @click="addPygame(scope.row)"
                >编辑</el-button
              > -->
              <el-button
                type="primary"
                size="small"
                style="margin-right: 10px"
                @click="selsctPygame(scope.row)"
                >选择</el-button
              >
              <!-- <el-dropdown trigger="click">
                <el-button type="primary" size="small">
                  管理<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="delPygame(scope.row)"
                    ><span>删除</span></el-dropdown-item
                  >
                  <el-dropdown-item @click.native="detailPygame(scope.row)"
                    ><span>详情</span></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </el-col>
    </el-row>
    <!-- 新增课程 dialog -->
    <addOrUpPygame
      :title="title"
      :show="pygameDialog"
      @close="
        pygameDialog = false;
        editData = null;
      "
      :editData="editData"
      @addForm="addForm"
      @editForm="editForm"
    ></addOrUpPygame>
    <detailPygame
      title="Pygame预置代码详情"
      :show="detailPygameDialog"
      @close="detailPygameDialog = false"
      :delData="delData"
    ></detailPygame>
  </el-row>
</template>

<script>
import addOrUpPygame from "@/components/research/pygame/add";
import detailPygame from "@/components/research/pygame/detail";
import { getWorksList, getWorkInfo, editWorkInfo } from "@/api/works/index";
import {
  $getTemplateList,
  $getTemplateInfo,
  $addTemplate,
  $editTemplate,
  $editTemplateTitle,
  $deleteTemplate,
} from "@/api/courseware";

export default {
  name: "PygameList",
  components: {
    addOrUpPygame,
    detailPygame,
  },
  data() {
    return {
      pygameModal: false,
      queryData: {},
      dataList: [],
      titleNameEdit: [],
      myarr: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      title: "",
      pygameDialog: false,
      editData: {},
      detailPygameDialog: false,
      delData: {},
      titleName: "",
      curIndex: -1,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //
    // 获取课程列表
    async getList() {
      let query = {
        ...this.queryData,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        source: "模板",
      };
      let res = await getWorksList(query);
      if (res.state == "success") {
        this.dataList = res.body.list;
        this.pagination.total = res.body.total;
        this.titleNameEdit = new Array(this.dataList.length).fill(false);
      }
    },

    // 重置
    reset() {
      this.queryData = {};
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getList();
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1;
      this.getList();
    },

    // 删除
    delPygame(row) {
      this.$confirm("确定删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $deleteTemplate({
            id: row.id,
          }).then((res) => {
            if (res.state === "success") {
              window.$msg("删除成功");
              this.getList();
            }
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 详情
    async detailPygame(row) {
      let id = row.id;
      // let res = await getWorkInfo({ id });
      // if (res.state == "success") {
      this.delData = row; // res.body;
      this.detailPygameDialog = true;
      // }
    },
    // 选择
    selsctPygame(data) {
      this.$emit("selsctPygame", data);
    },
    // 新增
    addPygame(data) {
      // this.title = "新增课件模板";
      // this.pygameModal = true;
      let routeUrl = this.$router.resolve({
        name: "PygameTemplate",
        query: {
          source: "模板",
          id: data.id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 编辑
    async upDatePygame(row) {
      this.title = "编辑课件模板";
      let id = row.id;
      let res = await $getTemplateInfo({ id });
      if (res.state == "success") {
        this.editData = res.body;
        this.pygameDialog = true;
      }
    },
    async addForm(data) {
      let res = await $addTemplate(data);
      if (res.state == "success") {
        window.$msg("新增课件模板成功");
        this.getList();
      }
    },
    async editForm(data) {
      let res = await $editTemplate(data);
      if (res.state == "success") {
        window.$msg("编辑课件模板成功");
        this.getList();
      }
    },
    async titileSure(row, index) {
      if (row.title == "") {
        window.$msg("模板名称不能为空", 2);
        return false;
      }
      let data = {
        id: row.id,
        title: row.title,
      };
      let res = await $editTemplateTitle(data);
      if (res.state == "success") {
        this.titleNameEdit.splice(index, 1, index);
        window.$msg("修改成功");
        this.getList();
      }
    },
    titleOff(row, index) {
      this.myarr.forEach((item) => {
        if (index == item.index) {
          row.title = item.title;
        }
      });
      this.titleNameEdit.splice(index, 1, false);
    },
    clickName(row, index) {
      if (this.curIndex != index) {
        this.titleNameEdit.splice(this.curIndex, 1, false);
      }
      let copy = {};
      copy = Object.assign({}, row, { index: index });
      this.myarr.push(copy);
      this.titleNameEdit.splice(index, 1, true);
      this.$nextTick(() => {
        this.$refs[index].focus();
      });
    },
    onfoucs(row, index) {
      this.curIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/style/table.scss";
</style>
<style>
.full-screen > .is-fullscreen > .el-dialog__header {
  display: none;
}
.full-screen > .is-fullscreen > .el-dialog__body {
  padding: 0;
}
</style>
