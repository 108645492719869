import { render, staticRenderFns } from "./rankList.vue?vue&type=template&id=5091da54&scoped=true"
import script from "./rankList.vue?vue&type=script&lang=js"
export * from "./rankList.vue?vue&type=script&lang=js"
import style0 from "./rankList.vue?vue&type=style&index=0&id=5091da54&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5091da54",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/frontend_coduck_manage_sbGx/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5091da54')) {
      api.createRecord('5091da54', component.options)
    } else {
      api.reload('5091da54', component.options)
    }
    module.hot.accept("./rankList.vue?vue&type=template&id=5091da54&scoped=true", function () {
      api.rerender('5091da54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/research/curriculum-manage/rankList.vue"
export default component.exports