var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        width: "60%",
        top: "7vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.taskTitle) } }),
        ]
      ),
      _vm.active === 1
        ? _c(
            "el-row",
            { staticClass: "task-list", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: { span: 6 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.seleTask(1)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "task-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/tuwen.png"),
                      },
                    }),
                    _c("p", [_vm._v("图文")]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  attrs: { span: 6 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.seleTask(2)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "task-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/tuwen.png"),
                      },
                    }),
                    _c("p", [_vm._v("课件")]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  attrs: { span: 6 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.seleTask(5)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "task-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/wailian.png"),
                      },
                    }),
                    _c("p", [_vm._v("python")]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  attrs: { span: 6 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.seleTask(3)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "task-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/kaoshi.png"),
                      },
                    }),
                    _c("p", [_vm._v("测试")]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { span: 6 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.seleTask(4)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "task-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/bianchen.png"),
                      },
                    }),
                    _c("p", [_vm._v("编程")]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { span: 6 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.seleTask(6)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "task-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/kaoshi.png"),
                      },
                    }),
                    _c("p", [_vm._v("PDF")]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { span: 6 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.seleTask(7)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "task-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/wailian.png"),
                      },
                    }),
                    _c("p", [_vm._v("视频")]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { span: 6 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.seleTask(10)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "task-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/kaoshi.png"),
                      },
                    }),
                    _c("p", [_vm._v("scratch")]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { span: 6 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.seleTask(11)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "task-item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/kaoshi.png"),
                      },
                    }),
                    _c("p", [_vm._v("scratchJr")]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 2,
              expression: "active === 2",
            },
          ],
        },
        [
          _vm.taskTypeNum === 1
            ? _c(
                "el-row",
                [
                  _c("image-text", {
                    key: 2,
                    ref: "imageText",
                    attrs: {
                      lessonId: _vm.lessonId,
                      type: "2",
                      editTaskData: _vm.editTaskData,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 2
            ? _c(
                "el-row",
                [
                  _c("course-ware", {
                    ref: "courseWare",
                    attrs: {
                      lessonId: _vm.lessonId,
                      editTaskData: _vm.editTaskData,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 10
            ? _c(
                "el-row",
                [
                  _c("scratch-text", {
                    ref: "scratchText",
                    attrs: {
                      lessonId: _vm.lessonId,
                      type: "10",
                      editTaskData: _vm.editTaskData,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 11
            ? _c(
                "el-row",
                { staticStyle: { "margin-top": "55px" } },
                [
                  _c("scratchjr-text", {
                    ref: "scratchjrText",
                    attrs: {
                      lessonId: _vm.lessonId,
                      type: "11",
                      editTaskData: _vm.editTaskData,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 13
            ? _c(
                "el-row",
                [
                  _c("outside-link", {
                    ref: "outsideLink",
                    attrs: {
                      lessonId: _vm.lessonId,
                      editTaskData: _vm.editTaskData,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 6
            ? _c(
                "el-row",
                [
                  _c("pdf-task", {
                    ref: "pdftask",
                    attrs: {
                      lessonId: _vm.lessonId,
                      editTaskData: _vm.editTaskData,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 12
            ? _c(
                "el-row",
                [
                  _c("ppt-task", {
                    ref: "ppttask",
                    attrs: {
                      lessonId: _vm.lessonId,
                      editUploadData: _vm.editTaskData,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 7
            ? _c(
                "el-row",
                [
                  _c("video-task", {
                    ref: "videotask",
                    attrs: {
                      headers: _vm.headers,
                      lessonId: _vm.lessonId,
                      editTaskData: _vm.editTaskData,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 5
            ? _c(
                "el-row",
                [
                  _c("python-text", {
                    key: 2,
                    ref: "pythonText",
                    attrs: {
                      lessonId: _vm.lessonId,
                      type: "5",
                      editTaskData: _vm.editTaskData,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 3
            ? _c(
                "el-row",
                [
                  _c("exam-test", {
                    ref: "examTest",
                    attrs: {
                      lessonId: _vm.lessonId,
                      editTaskData: _vm.editTaskData,
                      CourseInfo: _vm.CourseInfo,
                    },
                    on: {
                      updateTask: _vm.updateTask,
                      getExamInfo: _vm.getExamInfo,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 4
            ? _c(
                "el-row",
                [
                  _c("edit-code", {
                    ref: "editCode",
                    attrs: {
                      lessonId: _vm.lessonId,
                      editTaskData: _vm.editTaskData,
                      CourseInfo: _vm.CourseInfo,
                      taskTitle: _vm.taskTitle,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taskTypeNum === 14
            ? _c(
                "el-row",
                { staticStyle: { "margin-top": "55px" } },
                [
                  _c("pygame-text", {
                    ref: "pygameText",
                    attrs: {
                      lessonId: _vm.lessonId,
                      type: "13",
                      editTaskData: _vm.editTaskData,
                    },
                    on: { updateTask: _vm.updateTask },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.active === 3
        ? _c(
            "el-row",
            [
              _c(
                "el-form",
                { attrs: { "label-width": "150px" } },
                [
                  _c("el-form-item", { attrs: { label: "课件标题：" } }, [
                    _c(
                      "div",
                      { staticStyle: { width: "67%", display: "flex" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                              width: "100%",
                            },
                          },
                          [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请填写课件标题" },
                                    model: {
                                      value: _vm.courseTitle,
                                      callback: function ($$v) {
                                        _vm.courseTitle = $$v
                                      },
                                      expression: "courseTitle",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "margin-top": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          ref: "uploadCourseFile",
                                          staticClass: "upload-demo",
                                          attrs: {
                                            "auto-upload": false,
                                            "before-remove": _vm.beforeRemove,
                                            "file-list": _vm.fileCourseList,
                                            "before-upload":
                                              _vm.beforeAvatarUpload,
                                            "on-success":
                                              _vm.handleAvatarSuccess,
                                            "on-change": _vm.fileCourseChange,
                                            headers: _vm.headers,
                                            accept: ".zip",
                                            action: _vm.uploadUrl,
                                            name: "file",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "trigger",
                                                size: "small",
                                                type: "primary",
                                              },
                                              slot: "trigger",
                                            },
                                            [_vm._v("选取文件")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                size: "small",
                                                type: "success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.submitUpload()
                                                },
                                              },
                                            },
                                            [_vm._v("上传到服务器 ")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "el-upload__tip",
                                              attrs: { slot: "tip" },
                                              slot: "tip",
                                            },
                                            [
                                              _vm._v(
                                                "只能上传zip格式，且文件名不能包含中文，上传文件不能超过 200MB!"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "视频资源：" } }, [
                    _c(
                      "div",
                      { staticStyle: { width: "100%", display: "flex" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                              width: "100%",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请填写视频标题" },
                                      model: {
                                        value: _vm.videoTitle,
                                        callback: function ($$v) {
                                          _vm.videoTitle = $$v
                                        },
                                        expression: "videoTitle",
                                      },
                                    }),
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticStyle: { padding: "20px 0px" },
                                        model: {
                                          value: _vm.radio,
                                          callback: function ($$v) {
                                            _vm.radio = $$v
                                          },
                                          expression: "radio",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "补课视频" } },
                                          [_vm._v("补课视频")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "串讲视频" } },
                                          [_vm._v("串讲视频")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "margin-top": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            ref: "uploadVideoFile",
                                            staticClass: "upload-demo",
                                            attrs: {
                                              "auto-upload": false,
                                              "before-remove": _vm.beforeRemove,
                                              "file-list": _vm.fileVideoList,
                                              "on-change": _vm.fileVideoChange,
                                              headers: _vm.headers,
                                              "on-success":
                                                _vm.handleAvatarVideoSuccess,
                                              action: _vm.uploadVideoUrl,
                                              name: "file",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  slot: "trigger",
                                                  size: "small",
                                                  type: "primary",
                                                },
                                                slot: "trigger",
                                              },
                                              [_vm._v("选取视频")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  size: "small",
                                                  type: "success",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.submitVideoUpload()
                                                  },
                                                },
                                              },
                                              [_vm._v("上传到服务器 ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.seleTaskBtns
        ? _c(
            "el-row",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveTask },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }