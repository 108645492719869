var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _c("el-form-item", { attrs: { label: "任务上传：", required: "" } }, [
        _vm.formData.content !== null &&
        _vm.formData.content !== undefined &&
        _vm.changeBookFlag
          ? _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "dialog-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.changeBook },
                  },
                  [_vm._v("更换授课资料")]
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "el-upload",
                  {
                    ref: "uploadCourseFile",
                    attrs: {
                      drag: "",
                      "before-remove": _vm.beforeRemove,
                      "file-list": _vm.fileCourseList,
                      "before-upload": _vm.beforeAvatarUpload,
                      "on-success": _vm.handleAvatarSuccess,
                      "on-change": _vm.fileCourseChange,
                      headers: _vm.headers,
                      accept: ".zip",
                      action: _vm.uploadUrl,
                      name: "file",
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _vm._v("将文件拖到此处，或"),
                      _c("em", [_vm._v("点击上传")]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      },
                      [
                        _vm._v(
                          "只能上传zip格式，且文件名不能包含中文，上传文件不能超过 200MB!"
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "完成条件：", prop: "completeCondition" } },
        [
          _vm._v(" 至少观看  "),
          _c("el-input-number", {
            attrs: { min: 1 },
            model: {
              value: _vm.formData.completeCondition,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "completeCondition", $$v)
              },
              expression: "formData.completeCondition",
            },
          }),
          _vm._v(" 分钟 "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }