<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    width="60%"
    top="7vh"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="taskTitle"></span>
    </div>
    <!-- 步骤条 -->
    <!--    <el-steps :active="active" align-center finish-status="success" simple>-->
    <!--      <el-step title="选择任务类型"></el-step>-->
    <!--      <el-step title="设置任务内容"></el-step>-->
    <!--      <el-step title="上传任务资源"></el-step>-->
    <!--    </el-steps>-->

    <!-- 任务类型 -->
    <el-row :gutter="20" class="task-list" v-if="active === 1">
      <el-col :span="6" @click.native="seleTask(1)">
        <div class="task-item">
          <img src="../../../assets/images/tuwen.png"/>
          <p>图文</p>
        </div>
      </el-col>
      <el-col :span="6" @click.native="seleTask(2)">
        <div class="task-item">
          <img src="../../../assets/images/tuwen.png"/>
          <p>课件</p>
        </div>
      </el-col>
      <!--      <el-col :span="6" @click.native="seleTask(2)">-->
      <!--        <div class="task-item">-->
      <!--          <img src="../../../assets/images/wailian.png" />-->
      <!--          <p>外链</p>-->
      <!--        </div>-->
      <!--      </el-col>-->
      <el-col :span="6" @click.native="seleTask(5)">
        <div class="task-item">
          <img src="../../../assets/images/wailian.png"/>
          <p>python</p>
        </div>
      </el-col>
      <el-col :span="6" @click.native="seleTask(3)">
        <div class="task-item">
          <img src="../../../assets/images/kaoshi.png"/>
          <!-- <p>考试</p> -->
          <p>测试</p>
        </div>
      </el-col>

      <el-col :span="6" style="margin-top:20px;" @click.native="seleTask(4)">
        <div class="task-item">
          <img src="../../../assets/images/bianchen.png"/>
          <p>编程</p>
        </div>
      </el-col>
      <el-col :span="6" style="margin-top:20px;" @click.native="seleTask(6)">
        <div class="task-item">
          <img src="../../../assets/images/kaoshi.png"/>
          <p>PDF</p>
        </div>
      </el-col>
      <el-col :span="6" style="margin-top:20px;" @click.native="seleTask(7)">
        <div class="task-item">
          <img src="../../../assets/images/wailian.png"/>
          <p>视频</p>
        </div>
      </el-col>
      <!--      <el-col :span="6" style="margin-top:20px;"  @click.native="seleTask(8)">-->
      <!--        <div class="task-item">-->
      <!--          <img src="../../../assets/images/tuwen.png" />-->
      <!--          <p>文件</p>-->
      <!--        </div>-->
      <!--      </el-col>-->
      <!--      <el-col :span="6" style="margin-top:20px;"  @click.native="seleTask(9)">-->
      <!--        <div class="task-item">-->
      <!--          <img src="../../../assets/images/tuwen.png" />-->
      <!--          <p>goc</p>-->
      <!--        </div>-->
      <!--      </el-col>-->
      <el-col :span="6" style="margin-top:20px;" @click.native="seleTask(10)">
        <div class="task-item">
          <img src="../../../assets/images/kaoshi.png"/>
          <p>scratch</p>
        </div>
      </el-col>
      <el-col :span="6" style="margin-top:20px;"  @click.native="seleTask(11)">
        <div class="task-item">
          <img src="../../../assets/images/kaoshi.png" />
          <p>scratchJr</p>
        </div>
      </el-col>
    </el-row>
    <!-- 图文 -->
    <el-row v-show="active === 2">
      <el-row v-if="taskTypeNum === 1">
        <image-text ref="imageText" :lessonId="lessonId" :key="2" type='2' :editTaskData="editTaskData"
                    @updateTask="updateTask"></image-text>
      </el-row>

      <el-row v-if="taskTypeNum === 2">
        <course-ware ref="courseWare" :lessonId="lessonId" :editTaskData="editTaskData"
                     @updateTask="updateTask"></course-ware>
      </el-row>
      <!-- 文件 -->
      <!--    <el-row v-if="taskTypeNum === 8">-->
      <!--      <file-text ref="fileText" :lessonId="lessonId"  type='8' :editTaskData="editTaskData" @updateTask="updateTask"></file-text>-->
      <!--    </el-row>-->
      <!-- scratch -->
      <el-row v-if="taskTypeNum === 10">
        <scratch-text ref="scratchText" :lessonId="lessonId" type='10' :editTaskData="editTaskData"
                      @updateTask="updateTask"></scratch-text>
      </el-row>
    <!-- scratchjr -->
    <el-row v-if="taskTypeNum === 11" style="margin-top: 55px;">
      <scratchjr-text ref="scratchjrText" :lessonId="lessonId"  type='11' :editTaskData="editTaskData" @updateTask="updateTask"></scratchjr-text>
    </el-row>
      <!-- 外部链接 -->
      <el-row v-if="taskTypeNum === 13">
        <outside-link ref="outsideLink" :lessonId="lessonId" :editTaskData="editTaskData"
                      @updateTask="updateTask"></outside-link>
      </el-row>

      <!-- pdf任务 -->
          <el-row v-if="taskTypeNum === 6">
            <pdf-task ref="pdftask" :lessonId="lessonId"  :editTaskData="editTaskData" @updateTask="updateTask"></pdf-task>
          </el-row>

      <!-- ppt任务 -->
      <el-row v-if="taskTypeNum === 12">
        <ppt-task ref="ppttask" :lessonId="lessonId" :editUploadData="editTaskData" @updateTask="updateTask"></ppt-task>
      </el-row>
      <!-- 视频任务 -->
      <el-row v-if="taskTypeNum === 7">
        <video-task ref="videotask" :headers="headers" :lessonId="lessonId" :editTaskData="editTaskData"
                    @updateTask="updateTask"></video-task>
      </el-row>

      <!-- goc任务 -->
      <!--    <el-row v-if="taskTypeNum === 9" style="margin-top: 55px;">-->
      <!--      <goc-task ref="goctask" :lessonId="lessonId" :editTaskData="editTaskData" @updateTask="updateTask"></goc-task>-->
      <!--    </el-row>-->

      <!-- python外部链接 -->
      <el-row v-if="taskTypeNum === 5">
        <!--<outside-link ref="outsideLink" :lessonId="lessonId" type="5" :editTaskData="editTaskData" @updateTask="updateTask"></outside-link>-->
        <python-text ref="pythonText" :lessonId="lessonId" :key="2" type='5' :editTaskData="editTaskData"
                     @updateTask="updateTask"></python-text>
      </el-row>

      <!-- 试卷 -->
      <el-row v-if="taskTypeNum === 3">
        <exam-test ref="examTest" :lessonId="lessonId" :editTaskData="editTaskData" :CourseInfo="CourseInfo"
                   @updateTask="updateTask" @getExamInfo="getExamInfo"></exam-test>
      </el-row>

      <!-- 编程题库 -->
      <el-row v-if="taskTypeNum === 4">
        <edit-code ref="editCode" :lessonId="lessonId" :editTaskData="editTaskData" :CourseInfo="CourseInfo"
                   :taskTitle="taskTitle" @updateTask="updateTask"></edit-code>
      </el-row>

    <!-- pygame游戏 -->
    <el-row v-if="taskTypeNum === 14" style="margin-top: 55px;">
      <pygame-text ref="pygameText" :lessonId="lessonId"  type='13' :editTaskData="editTaskData" @updateTask="updateTask"></pygame-text>
    </el-row>
    </el-row>

    <el-row v-if="active === 3">
      <el-form label-width="150px">
        <el-form-item label="课件标题：">
          <div style="width:67%;display: flex;">
            <div style="display: flex;flex-direction: column;width:100%;">
              <div style="display: flex;">
                <div style="display: flex;flex-direction: column">
                  <el-input placeholder="请填写课件标题" v-model="courseTitle"></el-input>
                  <div style="display: flex;margin-top: 10px;">
                    <el-upload
                      :auto-upload="false"
                      :before-remove="beforeRemove"
                      :file-list="fileCourseList"
                      :before-upload="beforeAvatarUpload"
                      :on-success="handleAvatarSuccess"
                      :on-change="fileCourseChange"
                      :headers="headers"
                      ref="uploadCourseFile"
                      accept=".zip"
                      :action="uploadUrl"
                      class="upload-demo"
                      name="file"
                    >
                      <el-button size="small" slot="trigger" type="primary">选取文件</el-button>
                      <el-button
                        @click="submitUpload()"
                        size="small"
                        style="margin-left: 10px;"
                        type="success"
                      >上传到服务器
                      </el-button>
                      <div class="el-upload__tip" slot="tip">只能上传zip格式，且文件名不能包含中文，上传文件不能超过 200MB!</div>
                    </el-upload>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="视频资源：">
          <div style="width:100%;display: flex;">
            <div style="display: flex;flex-direction: column;width:100%;">
              <div style="display: flex;margin-bottom: 20px;">
                <div style="display: flex;flex-direction: column">
                  <el-input placeholder="请填写视频标题" v-model="videoTitle"></el-input>
                  <el-radio-group v-model="radio" style="padding:20px 0px">
                    <el-radio label="补课视频">补课视频</el-radio>
                    <el-radio label="串讲视频">串讲视频</el-radio>
                  </el-radio-group>
                  <div style="display: flex;margin-top: 10px;">
                    <el-upload
                      :auto-upload="false"
                      :before-remove="beforeRemove"
                      :file-list="fileVideoList"
                      :on-change="fileVideoChange"
                      :headers="headers"
                      :on-success="handleAvatarVideoSuccess"
                      ref="uploadVideoFile"
                      :action="uploadVideoUrl"
                      class="upload-demo"
                      name="file"
                    >
                      <el-button size="small" slot="trigger" type="primary">选取视频</el-button>
                      <el-button
                        @click="submitVideoUpload()"
                        size="small"
                        style="margin-left: 10px;"
                        type="success"
                      >上传到服务器
                      </el-button>
                    </el-upload>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- footer -->
    <el-row slot="footer" v-if="seleTaskBtns">
      <el-button size="small" type="primary" @click="saveTask">保存</el-button>
    </el-row>

  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute taskTitle 根据有无 taskTitle数据 动态渲染 dialog弹出框 数据
 * @attribute lessonId 课节 ID
 * @function close 自定义弹窗关闭事件
 */

import CourseWare from './components/course-ware' // 图文任务
import ImageText from './components/image-text' // 图文任务
import PythonText from './components/python-text' // 图文任务
import FileText from './components/file-text' // 图文任务
import OutsideLink from './components/outside-link' // 外联任务
import PdfTask from './components/pdf-task' // pdf 任务
import pptTask from './components/ppt-task' // ppt 任务
import VideoTask from './components/video-task' // 视频 任务
import ExamTest from './components/exam-test' // 试卷任务
import EditCode from './components/edit-code' // 编程任务
// import gocTask from './components/goc-task' // goc任务
import {getToken} from '@/utils/auth'
import {getTaskInfo} from '@/api/research/curriculum'
import {getExamInfo} from '@/api/research/exam'
import ScratchText from "./components/scratch-text";
import PygameText from "./components/pygame-text";
import ScratchjrText from "./components/scratchjr-text";


import {addAttachment} from '@/api/research/curriculum'
import {uploadCheck} from '@/api/video/videomanage'
import SparkMD5 from "spark-md5"

export default {
  components: {
    ScratchText,
    PdfTask,
    pptTask,
    ImageText,
    PythonText,
    CourseWare,
    OutsideLink,
    ExamTest,
    EditCode,
    VideoTask,
    FileText,
    PygameText,
    ScratchjrText,
    // gocTask
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    lessonId: {
      default: '',
      type: String
    },
    editTaskData: {
      default: () => {
        return {}
      },
      type: Object
    },
    CourseInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show() {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data() {
    return {
      radio: '',
      currenttaskID: '',
      taskTitle: '',
      active: 1,
      seleTaskBtns: false, // 底部按钮状态
      taskTypeNum: 0, // 任务类型 1-图文、2-外部链接、3-考试、4-编程题库 5-python外部链接 ,6-pdf ,7-视频
      dataObject: null,
      headers: {Authorization: ""},
      fileData: {},
      fileCourseList: [],
      fileVideoList: [],
      courseTitle: "",
      videoPath: "",
      videoTitle: "",
      hash: "",
      // uploadVideoUrl: process.env.VUE_APP_API_ADDRESS +'courseUnit/addAttachment',
      uploadVideoUrl: process.env.VUE_APP_API_ADDRESS + 'video/upload',
      // uploadUrl:  process.env.VUE_APP_API_ADDRESS +'courseUnit/uploadAttachment',
      uploadUrl: process.env.VUE_APP_API_ADDRESS + 'courseUnit/uploadAttachment',
      uploadForm: new FormData()
    }
  },
  methods: {
    fileCourseChange(file, fileList) {
      this.fileCourseList = fileList.splice(-1)
    },
    fileVideoChange(file, fileList) {
      let arr = file.name.split('.')
      if (arr && arr.length > 1) {
        let suffixName = arr[arr.length - 1].toLowerCase()
        if (suffixName !== 'mp4' && suffixName !== 'rmvb' && suffixName !== 'flv' && suffixName !== 'avi' && suffixName !== 'wmv') {
          this.$message.error('文件类型错误，请重新上传！')
          this.fileListArray = []
          return false
        }
      }
      this.fileVideoList = fileList.splice(-1)
      let _this = this
      // element 中组件对 file 进行加工，这里使用未加工的对象，只有未加工的对象才能在 blobSlice.call() 中正常操作
      let fileRaw = file.raw

      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let fileReader = new FileReader()
      let spark = new SparkMD5()
      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result)
        _this.hash = spark.end()
      }
      fileReader.onerror = function () {
        console.warn('FileReader error.')
      }
      fileReader.readAsBinaryString(blobSlice.call(fileRaw, 0, 1024 * 1024))
    },

    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 200
      if (!isLt2M) {
        window.$msg('上传文件不能超过 200MB!', 1)
      }
      return isLt2M
    },
    async submitVideoUpload() {
      if (this.videoTitle === '') {
        $msg('请填写视频标题后再上传', 2)
        return false
      } else if (this.fileVideoList.length === 0) {
        $msg("请选择视频后再上传", 2)
        return false
      } else if (this.radio === '') {
        $msg('请选择视频类型后再上传', 2)
        return false
      }
      const res = await uploadCheck({
        hash: this.hash
      })
      if (res.state === 'success') {
        if (!res.body) {
          this.$refs.uploadVideoFile.submit()
        } else {
          let formdata = new FormData()
          formdata.append("type", this.radio)
          formdata.append("taskId", this.currenttaskID)
          formdata.append("title", this.videoTitle)
          formdata.append("content", res.body)
          const response = await addAttachment(formdata)
          if (response && response.state === 'success') {
            window.$msg('上传成功')
            this.fileVideoList = []
            this.videoTitle = ""
          }
        }
      }
    },
    submitUpload(index) {
      if (this.courseTitle === '') {
        window.$msg('请填写课件标题后再上传', 2)
        return false
      } else if (this.fileCourseList.length === 0) {
        $msg("请选择课件后再上传", 2)
        return false
      }
      this.$refs.uploadCourseFile.submit()
    },

    async handleAvatarVideoSuccess(res, file, fileList) {
      if (res && res.body !== null) {
        let formdata = new FormData()
        formdata.append("type", this.radio)
        formdata.append("taskId", this.currenttaskID)
        formdata.append("title", this.videoTitle)
        formdata.append("content", res.body)
        const response = await addAttachment(formdata)
        if (response && response.state === 'success') {
          window.$msg('上传成功')
          this.fileVideoList = fileList.splice(0)
          this.videoTitle = ""
        }
      } else {
        window.$msg('上传文件失败', 2)
      }
    },
    async handleAvatarSuccess(res, file, fileList) {
      if (res && res.state === 'success') {
        this.uploadForm.append("taskId", this.currenttaskID)
        this.uploadForm.append('title', this.courseTitle)
        this.uploadForm.append('content', res.body)
        this.uploadForm.append('type', '课件')
        await addAttachment(this.uploadForm)
        window.$msg('上传成功')
        this.uploadForm = new FormData()
        this.fileCourseList = []
        this.courseTitle = ""
      } else {
        window.$msg('上传文件失败', 2)
      }
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },

    openDialog() {
      this.headers.Authorization = getToken()
      // 重置表单项
      // this.formData = {
      //   title: '',
      //   sourceContent: '',
      //   completeCondition: 1
      // }
      // this.testDada = []
      // this.dataObject = null
      if (this.editTaskData !== null) {
        this.taskTitle = '编辑任务'
        this.seleTaskBtns = true
        this.currenttaskID = this.editTaskData.id
        this.active = 2
        switch (this.editTaskData.type) {
          case '图文':
            this.taskTypeNum = 1
            break
          case '课件':
            this.taskTypeNum = 2
            break
          case '测试':
            this.taskTypeNum = 3
            break
          case '编程':
            this.taskTypeNum = 4
            break
          case 'python':
            this.taskTypeNum = 5
            break
          case 'pdf':
            this.taskTypeNum = 6
            break
          case '视频':
            this.taskTypeNum = 7
            break
          // case '上传':
          //   this.taskTypeNum = 8
          //   break
          case 'scratch':
            this.taskTypeNum = 10
            break;
          case 'scratchjr':
            this.taskTypeNum = 11
            break;
          case 'ppt':
            this.taskTypeNum = 12
            break;
          case '外链':
            this.taskTypeNum = 13
            break;
          case 'Pygame':
            this.taskTypeNum = 14
            break;
        }
        this.getTaskInfo(this.taskTypeNum)
      } else {
        this.taskTypeNum = 0
        this.seleTaskBtns = false
        this.active = 1
        this.taskTitle = '添加任务'
      }
    },

    // 添加\更新任务 成功回调
    updateTask(data) {
      this.$emit('updateView')
      this.close()
    },

    // 预览试卷
    previewPaper(row) {
      this.close()
      this.$router.push({
        path: '/admin/previewPaper/',
        query: {
          id: row.id
        }
      })
    },

    // 关闭弹窗
    close() {
      this.fileVideoList = []
      this.videoTitle = ""
      this.$emit('close')
    },

    // 下一步
    next() {
      if (this.active === 2 && this.currenttaskID === '') {
        window.$msg('请先保存任务在上传相关资源', 2)
        return false
      }
      this.active = this.active + 1
      if (this.active > 3) this.active = 3
      if (this.active === 3) {
        this.radio = ''
      }
    },

    // 上一步
    prev() {
      // 重置表单项
      this.formData = {
        title: '',
        sourceContent: '',
        completeCondition: 1
      }
      this.active = this.active - 1
      if (this.active < 1) this.active = 1
    },

    // 选择任务
    seleTask(status) {
      this.seleTaskBtns = true
      this.taskTypeNum = status
      this.next()
    },

    // 获取任务详细信息
    getTaskInfo(taskTypeNum) {
      getTaskInfo({
        id: this.editTaskData.id
      }).then(res => {
        if (res.state === 'success') {
          switch (taskTypeNum) {
            case 1:
              this.$refs.imageText.formData = res.body
              this.$refs.imageText.updateData()
              this.$forceUpdate()
              break
            case 2:
              this.$refs.courseWare.formData = {...res.body}
              this.$refs.courseWare.updateViewData()
              break
            case 3:
              this.$refs.examTest.formData = {...res.body}
              if (res.body.sourceId !== null && res.body.sourceId !== undefined) {
                this.getExamInfo(res.body.sourceId) // 获取试卷详细信息
              }
              break
            case 4:
              this.$refs.editCode.formData = {...res.body}
              break
            case 5:
              this.$refs.pythonText.formData = res.body
              this.$refs.pythonText.getTaskTopicList()
              this.$refs.pythonText.updateData()
              break
            case 6:
              this.$refs.pdftask.formData = { ...res.body }
              break
            case 12:
              this.$refs.ppttask.formData = {...res.body}
              this.$refs.ppttask.initData()
              break
            case 7:
              this.$refs.videotask.formData = {...res.body}
              this.$refs.videotask.updateViewData()
              break
            // case 9:
            //   let a = JSON.parse(res.body.sourceContent)
            //   this.$refs.goctask.formData =
            //   {
            //     ...res.body,
            //     description: a.description,
            //     code: a.code,
            //     type: a.type,
            //     cid: a.id,
            //     taskId: a.taskId
            //   }
            //   this.$refs.goctask.updateViewData()
            //   break
            case 10:
              this.$refs.scratchText.formData = {...res.body}
              this.$refs.scratchText.updateViewData()
              this.$refs.scratchText.getTaskTopicList()
              break;
            case 11:
              this.$refs.scratchjrText.formData = { ...res.body }
              this.$refs.scratchjrText.updateViewData()
              break
            case 13:
              this.$refs.outsideLink.formData = {...res.body}
              break
            case 14:
              this.$refs.pygameText.formData = { ...res.body }
              this.$refs.pygameText.updateViewData()
              break;
          }
        }
      })
    },

    // async getUploadTaskInfo() {
    //   const response =  await getUploadTaskInfo({
    //     taskId:this.editTaskData.id
    //   })
    //   this.$refs.fileText.formData.minFileSize = response.body.minSize
    //   this.$refs.fileText.formData.maxFileSize = response.body.maxSize
    //   this.$refs.fileText.formData.uploadType = JSON.parse(response.body.extList)
    // },

    getExamInfo(id) {
      getExamInfo({id}).then(res => {
        if (res.state === 'success') {
          this.$refs.examTest.testDada = []
          this.$refs.examTest.testDada.push(res.body)
        }
      })
    },

    // 保存任务
    saveTask() {
      if (this.editTaskData !== null) { // 编辑任务
        switch (this.taskTypeNum) {
          case 1:
            this.$refs.imageText.editTask()
            break
          case 2:
            this.$refs.courseWare.editTask()
            break
          case 3:
            this.$refs.examTest.editTask()
            break
          case 4:
            this.$refs.editCode.editTask()
            break
          case 5:
            this.$refs.pythonText.editTask(5)
            break
          case 6:
            this.$refs.pdftask.editTask()
            break
          case 7:
            this.$refs.videotask.editTask()
            break;
                    // case 9:
          //   this.$refs.goctask.editTask()
          //   break
          case 10:
            this.$refs.scratchText.editTask()
            break;
          case 11:
            this.$refs.scratchjrText.editTask();
            break;
          case 12:
            this.$refs.ppttask.saveUpload()
            this.$refs.ppttask.editTask()
            break;
          case 13:
            this.$refs.outsideLink.editTask()
            break;
          case 14:
            this.$refs.pygameText.editTask()
            break
        }
      } else { // 添加任务
        switch (this.taskTypeNum) {
          case 1:
            this.$refs.imageText.addTask()
            break
          case 2:
            this.$refs.courseWare.addTask()
            break
          case 3:
            this.$refs.examTest.addTask()
            break
          case 4:
            this.$refs.editCode.addTask()
            break
          case 5:
            this.$refs.pythonText.addTask()
            break
          case 6:
            this.$refs.pdftask.addTask()
            break
          case 7:
            this.$refs.videotask.addTask()
            break
          // case 8:
          //   this.$refs.fileText.addTask()
          //   break
          case 10:
            this.$refs.scratchText.addTask()
            break;
          case 11:
            this.$refs.scratchjrText.addTask()
            break
          case 12:
            this.$refs.ppttask.addTask()
            break;
          case 13:
            this.$refs.outsideLink.addTask()
            break;
          case 14:
            this.$refs.pygameText.addTask()
            break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";

.task-list {
  margin: 35px 0;
}

.task-item {
  display: flex;
  flex-direction: column;
  height: 150px;
  background: #f4f6f8;
  border-radius: 4px;
  border: 3px solid #f4f6f8;
  box-sizing: border-box;
  padding-top: 28px;
  align-items: center;
  cursor: pointer;

  &:hover {
    border: 3px solid #f5c319;
  }

  img {
    width: 32px;
    height: 32px;
  }

  p {
    font-size: 16px;
    margin-top: 25px;
  }
}

/deep/ .el-dialog {
  min-width: 888px;
}

/deep/ .el-dialog__footer {
  text-align: right;
}

/deep/ .el-table td {
  padding: 15px 0 !important;
}

/deep/ .el-table th {
  padding: 15px 0 !important;
  background: #fcfdff;
}

/deep/ .el-table th > .cell {
  font-size: 15px !important;
  font-weight: 400;
  color: #434343;
}

/deep/ .el-table .cell {
  font-size: 15px !important;
  font-weight: 400;
  color: #666;
}
</style>
