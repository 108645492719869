<template>
  <el-row style="padding-bottom: 25px;">
    <h3 class="tab-title">课程计划</h3>
    <el-row class="task-listcurr">
      <span>总课次：<font class="taskNum" v-text="LessonNum"></font></span>
      <span class="task-span">总任务数：<font class="taskNum" v-text="TaskNum">5</font></span>
      <el-button type="text" class="task-rightcurr task-cursor" @click="copyCur">复制课节</el-button>
      <el-button :disabled="btndisable" type="text" class="task-rightcurr task-cursor" @click="addLesson">新增课节</el-button>
      <div class="clearfix"></div>
      <!-- 任务列表 -->
      <draggable
        :reverse="reverse" style="padding-left: 20px;"
        v-model='curriculumArr'
        v-bind="dragOptionsMneu"
        handle=".el-icon-rank"
        tag='el-collapse'
        :move='getdata'
        @end='datadragEnd'>
        <el-collapse v-for="(item, index) in curriculumArr" @change="handleChange" v-model="activeNames" :key="index" :id="item.id">
          <el-collapse-item  :name="index" >
            <template slot="title">
              <el-tooltip class="item" effect="dark" content="已发布" placement="top">
                <i v-if="item.status === '已启用'" class="el-icon-circle-check iconflag"></i>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="未发布" placement="top">
                <i v-if="item.status === '已禁用'" class="el-icon-circle-close iconflagred"></i>
              </el-tooltip>
              <span>第{{ Number(Number(index) + 1) }}课：{{ item.title }}</span>
              <div style="flex: 1"></div>
              <el-button :disabled="btndisable" type="text" class="task-rightcurr task-cursor" @click.stop="addTask(item.id)">新增任务
              </el-button>
              <el-button :disabled="btndisable" v-if="CourseInfo.status === '未发布' ||  CourseInfo.courseGroup === '培训'" type="text" class=" el-icon-rank task-rightcurr list-group-item"
                         style="font-size: 16px"></el-button>
              <el-button :disabled="btndisable" type="text" class="task-rightcurr task-cursor" @click.stop="editLesson(item)">编 辑</el-button>
              <div class="clearfix"></div>
              <el-dropdown :disabled="btndisable" trigger="click" v-if="CourseInfo.status === '未发布' ||  CourseInfo.courseGroup === '培训'">
                <el-button type="text" @click.stop="" style="margin-left: 15px">
                  更 多
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item  v-if="item.status === '已启用'" type="success" size="mini"
                                    @click.native="gotoMakeStatus(item.id,1,item.title)">取消发布
                  </el-dropdown-item>
                  <el-dropdown-item  v-if="item.status === '已禁用'" type="warning" size="mini"
                                    @click.native="publishCourseUnit(item.id)">发布
                  </el-dropdown-item>
                  <el-dropdown-item class="task-cursor" @click.native="delLesson(item.id)">删 除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>

            <draggable
              :reverse="reverse"
              style="padding-left: 20px;"
              v-model='item.children'
              v-bind="dragOptions"
              tag='el-timeline'
              :move='getdata'
              handle=".el-icon-rank"
              @add="addClass"
              @end='datadragTaskEnd'>
              <el-timeline-item
                :parentid="activity.courseUnitId"
                :id="activity.id"
                v-for="(activity, index) in item.children"
                :key="index"
                :hide-timestamp="true"
                :timestamp="activity.createTime">
                <el-tooltip class="item" effect="dark" content="已发布" placement="top">
                  <i v-if="activity.status === '已启用'" class="el-icon-circle-check iconflag"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="未发布" placement="top">
                  <i v-if="activity.status === '已禁用'" class="el-icon-circle-close iconflagred"></i>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" :content="activity.title" placement="top" style="margin-top:-8px"
                            v-if="activity.title.length > 20">
                  <font>任务{{ index + 1 }}：{{
                      activity.title.length > 20 ? activity.title.substring(0, 20) : activity.title
                    }}</font>
                </el-tooltip>
                <font v-else style="margin-top:-8px"> 任务{{ index + 1 }}：{{ activity.title }} </font>
                <font class="tasktype" v-if="activity.type !== '课件'"> {{ activity.type === 'pdf'?'文档':activity.type }}</font>
                <font class="tasklearnPurpose" v-if="activity.learnPurpose === 'Y'">学员任务</font>
                <font class="taskpracticePurpose" v-if="activity.practicePurpose === 'Y'">课后练习</font>
                <el-dropdown :disabled="btndisable"  trigger="click" class="task-rightcurr ">
                  <el-button type="text" @click.stop="" style="margin-left: 15px">
                    更 多
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu style="padding: 10px;" slot="dropdown">
                    <el-dropdown-item v-if="activity.status === '已启用'" type="success" size="mini"
                                      @click.native="gotoMakeTaskStatus(activity.id,1,activity.title)">取消发布
                    </el-dropdown-item>
                    <el-dropdown-item v-if="activity.status === '已禁用'" type="warning" size="mini"
                                      @click.native="publishTask(activity.id)">发布
                    </el-dropdown-item>
                    <el-dropdown-item class="task-cursor" @click.native="delTask(activity.id)">删 除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button :disabled="btndisable" type="text" class="task-rightcurr" @click.stop="editBaseTask(activity)">编 辑</el-button>
                <el-button :disabled="btndisable" type="text" class=" el-icon-rank task-rightcurr list-group-item"
                           style="font-size: 16px"></el-button>
                <el-button type="text" v-if="CourseInfo.courseGroup !== '培训'" class="task-rightcurr"
                           @click.stop="giveLessonBook(activity,index,item.children)">授课资料
                </el-button>
                <el-dropdown trigger="click" class="task-rightcurr" v-if="activity.type !== '课件'">
                  <el-button type="text" @click.stop="" style="margin-left: 15px">
                    任务详情
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :disabled="btndisable" @click.native="editTask(activity)">编 辑</el-dropdown-item>
                    <el-dropdown-item type="text" class="task-cursor"
                                      @click.native="LookTask(activity,activity.id,activity.type)">预 览
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <span style="height: 1px;background-color: #e6e6e6;width: 100%;"></span>
              </el-timeline-item>

            </draggable>
          </el-collapse-item>
        </el-collapse>
      </draggable>
      <el-drawer
        :visible.sync="drawer"
        size="40%"
        :with-header="false">
        <div style="padding:15px;display: flex;flex-direction: column;width: 100%;overflow: auto" :style="{height: autoHeight + 'px'}">
          <div style="font-weight: bold;">
            <span>授课资料</span>
          </div>
          <div style="width: 100%;height: 1px;background-color: #e3e3e3"></div>
          <span style="font-size: 14px;font-weight: bold;">教案</span>
          <el-input
            v-if="!btndisable"
            type="textarea"
            :rows="10"
            placeholder="请输入教案内容"
            v-model="currentTaskInfo.teacherPlan">
          </el-input>
          <span style="font-size: 14px" v-else v-html="currentTaskInfo.teacherPlan"></span>
          <div>
            <el-button :disabled="btndisable" type="primary" size="mini" style="margin-top: 15px;" @click="teachPlan">保存教案</el-button>
            <hr/>
          </div>
          <span style="font-size: 14px;font-weight: bold;margin-top: 10px;">课件</span>
          <span v-if="datadrawListOne.length === 0" style="font-size: 14px;">
            暂无数据<br>
            <span style="font-size: 14px;font-weight:bold">温馨提示：</span><span style="font-size: 14px">课件类型只可选择一种，一经选择不可更改。</span>
            </span>

          <div v-if="datadrawListOne.length === 0">
            <el-button :disabled="btndisable" type="primary" size="mini" style="margin-top: 10px;" @click="uploadFileLocal(1)">添加本地课件</el-button>
            <el-button :disabled="btndisable" type="primary" size="mini" style="margin-top: 10px;" @click="addCoureseItemParen">添加在线课件</el-button>
            <hr/>
          </div>

          <div v-else>
            <el-table
              :data="datadrawListOne"
              class="tb-list mt-15"
              style="width: 100%;margin-bottom: 20px;"
              border>
              <el-table-column label="标题" align="center" prop="title">
                 <template slot-scope="scope">
                   <div style="line-height:40px;">
                   <span style="float:none">{{ scope.row.title}}</span>
                    <el-popover
                    placement="bottom"
                    width="400"
                    style="float:none"
                    v-model="coursewareTitleVsible"
                    trigger="click">
                      <el-row style="line-height:30px">
                        <el-col :span="8" style="text-align:right">课件名称：</el-col>
                        <el-col  :span="12"><el-input  size="small" v-model="coursewareTitle"></el-input></el-col>
                      </el-row>
                      <el-row style="margin-top: 10px;text-align:center">
                        <el-button size="small" type="primary" @click="changeCourseItem(scope.row)">提 交</el-button>
                        <el-button size="small" @click="coursewareTitleVsible=false,coursewareTitle=false">取 消</el-button>
                      </el-row>
                    <span style="float:none" slot="reference"><i class="el-icon-edit"></i></span>
                  </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="创建日期" align="center">
                <template slot-scope="scope">
                  <div style="text-align: center">{{ scope.row.createTime.substring(0, 11) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" prop="score">
                <template slot-scope="scope">
                  <el-button :disabled="btndisable" type="text" @click="editCourseWare(scope.row)">编 辑</el-button>
                  <el-button
                    type="text"
                    @click="viewCourseWare(scope.row)"
                  >预 览
                  </el-button
                  >
                  <el-button
                    type="text"
                    :disabled="btndisable"
                    @click="deleteCourseWare(scope.row)"
                  >删 除
                  </el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <span style="font-size: 14px;font-weight: bold;margin-top: 10px;">说课视频</span>
          <span v-if="datadrawListTwo.length === 0" style="font-size: 14px;">暂无数据</span>
          <div v-if="datadrawListTwo.length === 0">
            <el-button :disabled="btndisable" type="primary" size="mini" style="margin-top: 10px;" @click="uploadFile(2)">编辑</el-button>
            <hr/>
          </div>

          <div v-else>
            <el-table
              :data="datadrawListTwo"
              class="tb-list mt-15"
              style="width: 100%;margin-bottom: 20px;"
              border>
              <el-table-column label="标题" align="center" prop="title"></el-table-column>
              <el-table-column label="创建日期" align="center">
                <template slot-scope="scope">
                  <div style="text-align: center">{{ scope.row.createTime.substring(0, 11) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="互动视频" align="center">
                <template slot-scope="scope">
                  <div style="display: flex;align-items: center;justify-content: center">
                    <i :class="scope.row.interactiveModel === 'Y'? 'green' : 'red'"></i>
                    <span>{{ scope.row.interactiveModel === 'Y' ? '开' : '关' }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" prop="score">
                <template slot-scope="scope">
                  <el-button type="text" @click="addInteractive(scope.row)">互动视频</el-button>
                  <el-button :disabled="btndisable" type="text" @click="editUpload(scope.row)">修 改</el-button>
                  <el-button
                    type="text"
                    @click="viewCourseWare(scope.row)"
                  >预 览
                  </el-button
                  >
                  <el-button
                    :disabled="btndisable"
                    type="text"
                    @click="deleteCourseWare(scope.row)"
                  >删 除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <span style="font-size: 14px;font-weight: bold;margin-top: 10px;">串讲视频</span>
          <span v-if="datadrawListThree.length === 0" style="font-size: 14px;">暂无数据</span>
          <div v-if="datadrawListThree.length === 0">
            <el-button :disabled="btndisable" type="primary" size="mini" style="margin-top: 10px;" @click="uploadFile(3)">编辑</el-button>
            <hr/>
          </div>

          <div v-else>
            <el-table
              :data="datadrawListThree"
              class="tb-list mt-15"
              style="width: 100%;margin-bottom: 20px;"
              border>
              <el-table-column label="标题" align="center" prop="title"></el-table-column>
              <el-table-column label="创建日期" align="center">
                <template slot-scope="scope">
                  <div style="text-align: center">{{ scope.row.createTime.substring(0, 11) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" prop="score">
                <template slot-scope="scope">
                  <el-button :disabled="btndisable" type="text" @click="editUpload(scope.row)">修 改</el-button>
                  <el-button
                    type="text"
                    @click="viewCourseWare(scope.row)"
                  >预 览
                  </el-button
                  >
                  <el-button
                    :disabled="btndisable"
                    type="text"
                    @click="deleteCourseWare(scope.row)"
                  >删 除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-drawer>
      <!-- 回到顶部 -->
      <el-backtop :bottom="40" :visibility-height="50" target=".task-listcurr"></el-backtop>
    </el-row>
    <!-- 课节 dialog -->
    <add-lesson :show="lessonDialog" :CourseInfo="CourseInfo" @close="lessonDialog=false;editLessonData=null"
                :editLessonData="editLessonData"
                @updateView="updateView"></add-lesson>

    <!-- 任务 dialog -->
    <add-task :show="taskDialog" @close="taskDialog=false;editTaskData=null" :lessonId="lessonId"
              :editTaskData="editTaskData" @updateView="updateView" :CourseInfo="CourseInfo"></add-task>
    <!-- 新增任务 dialog -->
    <add-base-task :show="baseTaskDialog" :CourseInfo="CourseInfo" :editTaskData="editTaskData"
                   @close="baseTaskDialog=false" :courseUnitId="courseUnitId" @updateView="updateView"></add-base-task>
    <!-- 课件补课视频 dialog -->
    <missed-lesson :applyCourseData="applyCourseData" :show="applyCourseFlag"
                   @close="applyCourseFlag=false"></missed-lesson>

    <!-- 新增课件dialog -->
    <add-courseware :addMissCourseData="addMissCourseData" :show="dialogaddCourseWare"
                    @close="dialogaddCourseWare=false"></add-courseware>
    <upload-givelesson-book
      :editFlag="editFlag"
      :show="uploadDialog"
      :type="waretype"
      :editUploadData="editUploadData"
      :taskId="currentTaskInfo.id"
      @updateView='updateWareData'
      @close="uploadDialog=false;"
    />
    <copycur
      :show="copycurFlag"
      @close="copycurFlag = false"
    ></copycur>
    <!-- 互动视频事件 -->
    <interactive-video @updateView="updateWareData" :attachMentId="attachMentId" :taskList="thembTasklist"
                       :show="interactiveDialog"
                       @close="interactiveDialog=false"></interactive-video>
  </el-row>
</template>

<script>
import uploadGivelessonBook from "./uploadGivelessonBook";
import addLesson from './addLesson'
import copycur from './copycur'
import addCourseware from './addCourseware'
import missedLesson from './missedLesson'
import interactiveVideo from './InteractiveVideo'
import Tinymce from '@/components/newTinymce'
import addTask from './addTask'
import addBaseTask from './addBaseTask'
import draggable from 'vuedraggable'
import {
  delCourseUnit,
  delTask,
  dragSort,
  taskSort,
  setTaskStatus,
  setCourseUnitStatus,
  getListAttachment,
  editTask,
  addAttachment,
  resetAttachment,
  editAttachment,
  delAttachment
} from '@/api/research/curriculum'
import {getVideoUrl} from '@/api/video/videomanage'
import {getCourseUnitListOnly, getInfo} from '@/api/senate/class'
import {createCourseItem} from '@/api/courseware/index'
export default {
  components: {
    draggable,
    copycur,
    addLesson,
    addTask,
    addBaseTask,
    addCourseware,
    missedLesson,
    interactiveVideo,
    Tinymce,
    uploadGivelessonBook
  },
  props: {
    curriculumID: {
      default: '',
      type: String
    },
    CourseInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  computed: {
    dragOptions() {
      return {
        group: 'description',
        ghostClass: 'ghost'
      }
    },
    dragOptionsMneu() {
      return {
        group: 'dragOptionsMneu',
        ghostClass: 'ghost'
      }
    }
  },
  mounted() {
    this.autoHeight = document.body.clientHeight
    this.getCourseUnitListOnly()
    if(this.CourseInfo.isCopy === 'N') {
      this.btndisable = false
    }else if(this.CourseInfo.isCopy === 'Y'){
      this.btndisable = true
    }
  },
  data() {
    return {
      btndisable:false,
      autoHeight:0,
      waretype: 0,
      uploadDialog: false,
      datadrawListOne: [],
      datadrawListTwo: [],
      datadrawListThree: [],
      editUploadData: {},
      drawer: false,
      currentTaskInfo: {},
      courseUnitId: '',
      reverse: false,
      lessonDialog: false,
      taskDialog: false,
      baseTaskDialog: false,
      editFlag: 0,
      editTaskData: null,
      editLessonData: null,
      curriculumArr: [], // 总课节
      originTaskList: this.taskList,
      originCurriculumList: [],
      targetId: '', // 顺序 -> 目标Id
      sourceId: '', // 顺序 -> 来源Id
      targetParentId: '', // 顺序 -> 目标父级Id
      sourceParentId: '', // 顺序 -> 来源父级Id
      lessonId: '', // 课节 ID
      LessonNum: 0, // 总课节-数目
      TaskNum: 0, // 总任务-数目
      courseUnitNum: '', // 当前课节下的任务数
      applyCourseData: {},
      applyCourseFlag: false,
      addMissCourseData: {},
      dialogaddCourseWare: false,
      activeNames: [],
      userIsData: true, // 用户是否要请求数据
      uploadForm: new FormData(),
      coursewareTitle: '',
      coursewareTitleVsible: false,
      currentCourseWareInfo:{},
      currentA:{},
      copycurFlag:false,

      attachMentId:'',
      interactiveDialog:false,
      thembTasklist:[],
    }
  },
  watch: {
    curriculumArr: {
      handler(newVal, oldVal) {
        this.curriculumArr = newVal
      },
      immediate: true,
      deep: true
    },
    activeNames(newVal, oldVal) {
      this.$router.push({
        path: this.$route.path,
        query: {
          curriculumID: this.curriculumID,
          tab: this.$route.query.tab,
          group: JSON.stringify(newVal)
        }
      })
      if (newVal.length > oldVal.length) {
        this.userIsData = true
      } else {
        this.userIsData = false
      }
    },
  },
  methods: {
    // 互动视频
    addInteractive(row) {
      this.attachMentId = row.id
      this.interactiveDialog = true
    },
    copyCur() {
      this.copycurFlag = true
    },
    setCourseInfo(data) {
      if(data.isCopy === 'N') {
        this.btndisable = false
      }else if(data.isCopy === 'Y'){
        this.btndisable = true
      }
    },
    handleChange(activeName) {
      if(activeName.length !== 0) {
        this.showTaskList(this.curriculumArr[activeName[activeName.length - 1]], activeName[activeName.length - 1])
      }
    },
    editUpload(row) {
      this.editUploadData = row
      this.editFlag = 1
      switch (row.type) {
        case '课件':
          this.waretype = 1
          const routeUrl = this.$router.resolve({name:'Edits'})
          window.open(routeUrl.href,'_blank')
          break
        case '补课视频':
          this.waretype = 2
          this.uploadDialog = true

          break
        case '串讲视频':
          this.waretype = 3
          this.uploadDialog = true
          break
      }
    },
    uploadFileLocal(flag){
      this.editFlag = 0
      this.waretype = flag
      this.editUploadData = {
        title: ''
      }
      this.uploadDialog = true
    },
    uploadFile(flag) {
      this.editFlag = 0
      this.waretype = flag
      this.editUploadData = {
        title: ''
      }
      if(flag === 1){
        const routeUrl = this.$router.resolve({
          name:'Edits',
          query:{
            content: this.currentA.content,
            ...this.currentTaskInfo,
            courseId: this.$route.query.courseId,
            courseName: this.$route.query.courseName,
          }
          })
        window.open(routeUrl.href,'_blank')
      } else {
        this.uploadDialog = true
      }
    },
    addCoureseItemParen(){
      const data = {
        sourceId: this.currentTaskInfo.id,
        level1: {
          categoryId: this.$route.query.courseId,
          categoryName: this.$route.query.courseName,
        },
        level2: {
          categoryId: this.currentTaskInfo.courseUnitId,
          categoryName: this.currentTaskInfo.courseUnitTitle,
        },
        sort: this.currentTaskInfo.sort,
        title	: this.currentTaskInfo.title,
        catalogId: this.currentTaskInfo.courseUnitId,
        version: '0.0.0',
        content: "https://kdyfiles.oss-cn-hangzhou.aliyuncs.com/kdy1bb7becedd2b45ae8f5ca2643fb1eba0.json",
        num: 1,
        remark:'',
        type:'ppt',
      };
      createCourseItem(data).then(res=>{
        if(res.state=='success'){
          this.currentCourseWareInfo = res.body;
          this.$nextTick(()=>{
            this.addCoureseItem(res.body.id)
          })
        }
      })
    },
    changeCourseItem(row){
      const params = new FormData();
      params.append('content', row.icontentd)
      params.append('taskId', this.currentTaskInfo.id)
      params.append('courseUnitId', this.currentTaskInfo.courseUnitId)
      if(this.coursewareTitle){
        params.append('title', this.coursewareTitle)
      }else{
        params.append('title', this.currentTaskInfo.title)
      }
      params.append('type', row.type)
      params.append('onlinePpt', row.onlinePpt)
      editAttachment({
        id: row.id,
        title: this.coursewareTitle||this.currentTaskInfo.title
      }).then(res=>{
          this.getCourseWareList()
          this.coursewareTitleVsible = false;
          this.coursewareTitle = null;
          this.uploadForm = new FormData();
        })
    },
    addCoureseItem(id){//静默创建课件
      this.uploadForm.append('taskId', this.currentTaskInfo.id)
      this.uploadForm.append('courseUnitId', this.currentTaskInfo.courseUnitId)
      if(this.coursewareTitle){
        this.uploadForm.append('title', this.coursewareTitle)
      }else{
        this.uploadForm.append('title', this.currentTaskInfo.title)
      }
      this.uploadForm.append('onlinePpt', 'Y')
      this.uploadForm.append('type', '课件')
      if(this.datadrawListOne.length>0){
        this.uploadForm.append('content', this.datadrawListOne[0].content)
        this.uploadForm.append('id', this.datadrawListOne[0].id)
        resetAttachment(this.uploadForm).then(res=>{
          this.getCourseWareList()
          this.coursewareTitleVsible = false;
          this.coursewareTitle = null;
          this.uploadForm = new FormData();
        })
      }else{
        this.uploadForm.append('content', id)
        addAttachment(this.uploadForm).then(res=>{
        this.getCourseWareList()
        this.coursewareTitleVsible = false;
        this.coursewareTitle = null;
        this.uploadForm =  new FormData();
      })
      }

    },
    teachPlan() {
      editTask({
        id: this.currentTaskInfo.id,
        teacherPlan: this.currentTaskInfo.teacherPlan,
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('编辑成功')
          this.getCourseUnitListOnly()
        }
      })
    },
    updateWareData() {
      this.getCourseWareList()
    },
    async getCourseWareList() {
      const res = await getListAttachment({
        taskId: this.currentTaskInfo.id,
        pageNum: 1,
        pageSize: 10000,
        needCount: true
      });

      this.datadrawListOne = []
      this.datadrawListTwo = []
      this.datadrawListThree = []
      res.body.list.forEach((item) => {
        if (item.type === "课件") {
          this.datadrawListOne.push(item)
        } else if (item.type === "补课视频") {
          this.datadrawListTwo.push(item)
        } else if (item.type === "串讲视频") {
          this.datadrawListThree.push(item)
        }
      })

      this.drawer = true
    },

    // 删除课件
    deleteCourseWare(row) {
      this.$confirm("是否删除当前文件", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await delAttachment({id: row.id});
        window.$msg("删除成功");
        this.getCourseWareList();
      });
    },
    editCourseWare(row){
      this.currentA = row;
      if(row.onlinePpt==='Y' || (row.content !== null && row.content.indexOf('/')<0)){
        this.uploadFile(1)
      } else {
        this.editFlag = 1
        this.waretype = 1
        this.editUploadData = {
          title: row.title,
          id: row.id
        }
        this.uploadDialog = true
      }
    },
    viewCourseWare(row) {
      if (row.type === "课件") {
        if(row.onlinePpt==='Y' || (row.content !== null && row.content.indexOf('/')<0)){
          let pptRouteData = this.$router.resolve({
            path: "/ppt/looks",
            query: {
              sourceContent: row.content,
              content: row.content,
              ...this.currentTaskInfo,
              courseId: this.$route.query.courseId,
              courseName: this.$route.query.courseName,
            },
          } );
          window.open(pptRouteData.href, "_blank");
        } else {
          let routeData = this.$router.resolve({
            path: "/research/preview-accessory",
            query: {
              title: row.title,
              sourceContent: row.content,
              id: row.id,
            },
          } );
          window.open(routeData.href, "_blank");
        }
      } else if (row.type === "补课视频" || row.type === "串讲视频") {
        let routeData = this.$router.resolve({
          path: "/research/preview-video",
          query: {
            sourceVideoId: row.content,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    async getCourseUnitListOnly() {
      this.TaskNum = 0
      const res1 = await getCourseUnitListOnly({
        pageNum: 1,
        pageSize: 10000,
        courseId: this.curriculumID
      })
      if (!this.$route.query.group || JSON.parse(this.$route.query.group).length === 0) {
        this.curriculumArr = res1.body.list
        this.LessonNum = this.curriculumArr.length
        this.curriculumArr.forEach((ele, idx) => {
          this.TaskNum += Number(ele.taskCount)
        })
      } else {
        this.originCurriculumList = res1.body.list
        this.getGroupData(res1) // 获取用户已打开标签子数据
      }
    },
    getGroupData (res) {
      res.body.list.forEach((ele, idx) => {
        JSON.parse(this.$route.query.group).forEach((item, index) => {
          if (Number(idx) === Number(item)) {
            this.setTaskList(ele, index)
          }
        })
      })
    },

    setTaskList (row, idx) {
      getInfo({
        id: row.id,
        onlyEnable: false
      }).then(res => {
        if (res.state === 'success') {
          this.originCurriculumList.forEach((ele, idx) => {
            if (ele.id === res.body.id) {
              if (!this.originCurriculumList[idx].children) {
                this.originCurriculumList[idx].children = res.body.taskList
              }
            }
          })
          if (JSON.parse(this.$route.query.group).length === Number(idx + 1)) {
            this.curriculumArr = [...this.originCurriculumList]
            this.LessonNum = this.curriculumArr.length
            this.curriculumArr.forEach((ele, idx) => {
              this.TaskNum += Number(ele.taskCount)
            })
            setTimeout(()=> {
              this.activeNames = JSON.parse(this.$route.query.group)
            }, 300)
          }
        }
      })
    },
    showTaskList(row, idx) {
      getInfo({
        id: row.id,
        onlyEnable: false
      }).then(res => {
        if (res.state === 'success') {
          this.curriculumArr.forEach((ele, idx) => {
            if (ele.id === res.body.id) {
              if (!this.curriculumArr[idx].children) {
                this.curriculumArr[idx].children = res.body.taskList
              }
            }
          })
          this.originCurriculumList = [...this.curriculumArr]
          this.$forceUpdate()
        }
      })
    },

    gotoMakeStatus(id, type, name) {
      let stringTips = ""
      if (type === 1) {
        stringTips = '是否撤销发布，撤销发布后用户可不见'
      } else {
        stringTips = '发布<<' + name + '>>课节, 是否继续?'
      }

      this.$confirm(stringTips, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (type === 1) {
          this.unPublishCourseUnit(id)
        } else {
          this.publishCourseUnit(id)
        }
      }).catch(() => {

      });
    },
    // 发布课节
    publishCourseUnit(id) {
      setCourseUnitStatus({
        ids: [id],
        status: '已启用'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('操作成功')
          this.updateView()
        } else {
          $msg(res.errMsg, 2)
        }
      })
    },

    // 取消发布课节
    unPublishCourseUnit(id) {
      setCourseUnitStatus({
        ids: [id],
        status: '已禁用'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('操作成功')
          this.updateView()
        } else {
          $msg(res.errMsg, 2)
        }
      })
    },
    gotoMakeTaskStatus(id, type, name) {
      let stringTips = ""
      if (type === 1) {
        stringTips = '是否撤销发布，撤销发布后用户可不见'
      } else {
        stringTips = '发布<<' + name + '>>任务, 是否继续?'
      }

      this.$confirm(stringTips, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (type === 1) {
          this.unPublishTask(id)
        } else {
          this.publishTask(id)
        }
      }).catch(() => {

      });
    },
    // 发布任务
    publishTask(id) {
      setTaskStatus({
        ids: [id],
        status: '已启用'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('操作成功')
          this.updateView()
        } else {
          $msg(res.errMsg, 2)
        }
      })
    },

    // 取消发布任务
    unPublishTask(id) {
      setTaskStatus({
        ids: [id],
        status: '已禁用'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('操作成功')
          this.updateView()
        } else {
          $msg(res.errMsg, 2)
        }
      })
    },
    missedLessonManage(row) {
      this.applyCourseData = row
      this.applyCourseFlag = true
    },
    // addMissedLesson (row) {
    //   this.addMissCourseData = row
    //   this.dialogaddCourseWare = true
    // },
    // 添加课
    addLesson() {
      this.lessonDialog = true
    },

    // 编辑课
    editLesson(item) {
      this.editLessonData = item
      this.lessonDialog = true
    },

    // 添加任务
    addTask(id) { // 函数参数为 课节id
      this.editTaskData = null
      this.courseUnitId = id // 课节id
      this.baseTaskDialog = true
    },

    // 预览
    LookTask(activity, taskID, type) {
      if (type === "视频") {
        let routeData = this.$router.resolve({
          path: '/research/preview-video',
          query: {
            sourceVideoId: activity.sourceId,
          }
        })
        window.open(routeData.href, '_blank')
      } else {
        let routeData = null
        if (type === "pdf") {
          routeData = this.$router.resolve({
            path: '/research/pdfpreview',
            query: {
              curriculumID: this.curriculumID, // 课程id
              taskID: taskID // 任务id
            },
          })
        } else {
          routeData = this.$router.resolve({
            path: '/research/preview-task',
            query: {
              curriculumID: this.curriculumID, // 课程id
              taskID // 任务id
            },
            params: {id: this.id}
          })
        }
        window.open(routeData.href, '_blank')
      }
    },
    async getVideoUrl(fileId) {
      const res = await getVideoUrl({
        fileId: fileId
      })
      if (res.code === 1) {
        $msg(res.msg, 2)
      } else if (res.code === 0) {
        let routeData = this.$router.resolve({
          path: '/research/preview-video',
          query: {
            pathUrl: res.body
          }
        })
        window.open(routeData.href, '_blank')
      }
    },
    giveLessonBook(row,index,taskList) {
      this.thembTasklist = taskList
      row.sort = index +1;
      localStorage.setItem('currentTaskInfo',JSON.stringify(row))
      this.currentTaskInfo = row
      this.getCourseWareList()
    },
    editBaseTask(row) {
      this.courseUnitId = row.courseUnitId // 课节Id
      this.editTaskData = row
      this.baseTaskDialog = true
    },
    // 编辑任务
    editTask(row) {
      this.lessonId = row.courseUnitId // 课节Id
      this.editTaskData = row
      this.taskDialog = true
    },
    previewResource(row) {
      this.applyCourseData = row
      this.applyCourseFlag = true
    },
    // 删除课节
    delLesson(id) {
      this.$confirm('确定删除？已发布的课程删除课或任务后，已购课学员将无法查看该课或任务', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delCourseUnit({
            ids: [id]
          })
          window.$msg('删除成功')
          this.getCourseUnitListOnly()
        })
        .catch(() => {
          return false
        })
    },
    // 删除任务
    delTask(id) {
      this.$confirm('确定删除？已发布的课程删除课或任务后，已购课学员将无法查看该课或任务', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTask({
            ids: [id]
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('删除成功')
              this.getCourseUnitListOnly()
            }
          })
        })
        .catch(() => {
          return false
        })
    },

    // 拖动中与拖动结束
    getdata({dragged, related}) {
      this.sourceId = dragged.getAttribute('id')
      this.targetId = related.getAttribute('id')
      this.sourceParentId = dragged.getAttribute('parentid')
      this.targetParentId = related.getAttribute('parentid')
    },

    // 课节拖拽结束
    datadragEnd(evt) {
      if (evt.oldIndex !== evt.newIndex) {
        dragSort({
          id: this.sourceId,
          toId: this.targetId
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('操作成功')
            this.updateView()
          }
        })
      }
    },

    // 拖拽 add 事件
    addClass(evt) {
      this.targetParentId = evt.path[4].getAttribute('id')
    },

    // 任务-子项拖拽排序
    datadragTaskEnd(evt) {
      if (this.sourceParentId !== this.targetParentId) {
        taskSort({
          id: this.sourceId,
          sort: evt.newIndex > evt.oldIndex ? evt.newIndex + 2 : evt.newIndex+ 1,
          courseUnitId: this.targetParentId
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('操作成功')
            this.updateView()
          }
        })
      } else {
        if (evt.oldIndex !== evt.newIndex) {
          // let sort = 0
          // if (evt.newIndex === 0) {
          //   sort = evt.newIndex + 0.5
          // } else {
          //   sort = evt.newIndex + 1.5
          // }
          taskSort({
            id: this.sourceId,
            sort: evt.newIndex > evt.oldIndex ? evt.newIndex + 2 : evt.newIndex+ 1,
            courseUnitId: this.targetParentId
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('操作成功')
              this.updateView()
            }
          })
        } else {
          return false
        }
      }
    },

    // 编辑成功，更新视图
    updateView() {
      // this.userIsData = false
      // this.activeNames = []
      this.getCourseUnitListOnly()
    }
  }
}
</script>

<style lang="scss" scoped>
.red {
  display: block;
  background: #f00;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin-right: 5px;
}
.green {
  display: block;
  background: #0CA51A;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin-right: 5px;
}
.tasktype {
  color: #722ED1;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: 10px;
  border: solid 1px #d3adf7;
  border-radius: 5px;
  background-color: #f9f0ff;
}

.tasklearnPurpose {
  color: #52c41a;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: 10px;
  border-radius: 5px;
  border: solid 1px #b7eb8f;
  background-color: #f6ffed;
}

.taskpracticePurpose {
  color: #fa541c;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 5px;
  margin-left: 10px;
  border: solid 1px #ffbb96;
  background-color: #fff2e8;
}

.iconflag {
  font-size: 18px;
  color: #4DA54D;
  margin-right: 5px;
}

.iconflagred {
  font-size: 18px;
  color: #CE433F;
  margin-right: 5px;
}

.tab-title {
  padding-left: 35px;
  margin: 20px 0;
  font-size: 17px;
  color: #464646;
  font-weight: 600;
}

.tab-input {
  width: 350px;
}

/deep/ .el-form-item {
  margin-bottom: 20px;
}

.task-listcurr {
  width: 80%;
  box-sizing: border-box;
  padding-left: 25px;

  span {
    float: left;
    line-height: 40px;
    font-size: 16px;
    color: #000000;
  }

  .task-span {
    margin-left: 15px;
  }

  .taskNum {
    color: #f5c319;
  }

  .task-rightcurr {
    float: right;
    margin-left: 10px;
  }

  .task-cursor {
    cursor: pointer;
  }
}

.list-group-item {
  cursor: move;
}

/deep/ .ghost {
  border: 1px solid #aaaaaa;
}

/deep/ .el-timeline-item {
  padding: 15px 0;
}

/deep/ .el-timeline {
  margin: 15px 0;
}

/deep/ .scrollTop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  background: #f5c319;
  border-radius: 50%;
}

/deep/ .el-icon-arrow-right:before {
  content: '';
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}

.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
</style>
