var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "任务内容：", prop: "sourceContent" } },
        [
          _c("Tinymce", {
            key: _vm.formData.id,
            ref: "tinymceref",
            attrs: { width: "100%", height: 350 },
            model: {
              value: _vm.formData.sourceContent,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "sourceContent", $$v)
              },
              expression: "formData.sourceContent",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "完成条件：", prop: "completeCondition" } },
        [
          _vm._v(" 至少观看  "),
          _c("el-input-number", {
            attrs: { min: 1 },
            model: {
              value: _vm.formData.completeCondition,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "completeCondition", $$v)
              },
              expression: "formData.completeCondition",
            },
          }),
          _vm._v(" 分钟 "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }