<template>
  <el-dialog :visible.sync="show" :before-close="close" @open="openDialog" :close-on-click-modal="false">
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="interactiveTitle"></span>
    </div>
    <el-alert class="infinite-list"
              title="互动视频能够使老师在课堂中使用授课大屏播放视频自动触发特定事件（仅限于启用【互动课堂】功能时使用)"
              type="warning"
              :closable="false">
      <i class="el-icon-info infinite-i"></i>
    </el-alert>
    <el-form label-width="80px">
<!--      <el-form-item label="开关状态:">-->
<!--        <el-switch-->
<!--          v-model="switchSet"-->
<!--          active-text="开"-->
<!--          inactive-text="关">-->
<!--        </el-switch>-->
<!--      </el-form-item>-->
      <el-form-item label="事件配置:">
        <template slot-scope="scope">
          <ul class="list" style="list-style: none;">
            <li v-for="(item, index) in dataList" :key="index" style="display:flex;align-items: center;height: 50px">
              <div style="display: inline-block;">{{ index + 1 }}.视频播放到</div>
              <el-input disabled class="el-inp-fm" v-model="item.minite" placeholder="分"></el-input>分
              :
              <el-input disabled v-model="item.second" class="el-inp-fm" placeholder="秒"></el-input>秒，
              <div v-if="item.eventType === '推送'">
                <div style="display: inline-block;">推送任务
                  <el-select disabled style="width: 280px" v-model="item.pushTaskId" placeholder="请选择要推送的任务">
                    <el-option
                      v-for="item in taskList"
                      :key="item.id"
                      :label="item.title+' ['+item.type+']'"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div v-else-if="item.eventType === '结束推送'">
                结束任务推送
              </div>
              <div v-else-if="item.eventType === '暂停'" style="display: flex;align-items: center;">
                <span style="width: 140px">暂停视频，弹窗提示</span>
                <el-input disabled style="width: 250px" v-model="item.pushRemark" type="textarea"  :rows="2" maxlength="50" placeholder="输入弹窗内容，留空不弹"></el-input>
              </div>
<!--              <el-tooltip class="item" effect="dark" content="移除此项" placement="top-start">-->
<!--                <el-button type="text" class="deleteClass" icon="el-icon-remove-outline"-->
<!--                           @click="deleteInter(index)"></el-button>-->
<!--              </el-tooltip>-->
            </li>
          </ul>
<!--          <el-dropdown trigger="click">-->
<!--            <el-button size="small" style="margin-top: 10px">-->
<!--              添加事件<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--            </el-button>-->
<!--            <el-dropdown-menu slot="dropdown">-->
<!--              <el-dropdown-item @click.native="addInteractive('推送')">推送任务</el-dropdown-item>-->
<!--              <el-dropdown-item @click.native="addInteractive('结束推送')">结束推送任务</el-dropdown-item>-->
<!--              <el-dropdown-item @click.native="addInteractive('暂停')">暂停视频</el-dropdown-item>-->
<!--            </el-dropdown-menu>-->
<!--          </el-dropdown>-->
        </template>
      </el-form-item>
    </el-form>
<!--    <el-row slot="footer">-->
<!--      <el-button type="primary" @click="save">保 存</el-button>-->
<!--      <el-button @click="close">取 消</el-button>-->
<!--    </el-row>-->
  </el-dialog>
</template>

<script>
import {
  editAttachment,
  getInteractiveConfigById
} from '@/api/research/curriculum'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    taskList: {
      default: [],
      type: Array
    },
    attachMentId: {
      default: '',
      type: String
    }

  },
  data() {
    return {
      switchSet: false,
      interactiveTitle: '互动视频配置',
      selectData: [],
      dataList: [],
    }
  },
  methods: {
    openDialog() {
      this.dataList = []
      this.switchSet = false
      this.getInteractiveList()
    },
    // 关闭弹窗
    close() {
      this.$emit('close')
    },
    async getInteractiveList() {
      const response = await getInteractiveConfigById({
        id: this.attachMentId
      })
      if (response && response.state === 'success') {
        response.body.interactiveConfig.forEach(item => {
          item.minite = parseInt(parseInt(item.eventTime) / 60)
          item.second = parseInt(item.eventTime) % 60
        })
        if (response.body) {
          this.dataList = response.body.interactiveConfig
          this.switchSet = response.body.interactiveModel === 'Y' ? true : false
        }
      }
    },
    addInteractive(flag) {
      let object = {
        minite: '',
        second: '',
        eventType: flag
      }
      if (flag === '推送') {
        object.pushTaskId = ''
      }
      this.dataList.push(object)
    },
    async save() {
      let thembArray = this.dataList
      for (let i = 0; i < thembArray.length; i++) {
        if (thembArray[i].minite === '' || thembArray[i].second === '') {
          window.$msg('请将事件发生的时间填写完整在提交', 2)
          return false
        } else if (thembArray[i].eventType === '推送' && thembArray[i].pushTaskId === '') {
          window.$msg('推送类型的事件选择推送任务在提交', 2)
          return false
        }
      }
      let interactiveConfigBeanList = []
      thembArray.forEach(item => {
        item.eventTime = parseInt(item.minite) * 60 + parseInt(item.second)
        interactiveConfigBeanList.push(item)
      })
      let response = await editAttachment({
        id: this.attachMentId,
        interactiveModel: this.switchSet ? 'Y' : 'N',
        interactiveConfigBeanList: interactiveConfigBeanList
      })
      if (response && response.state === 'success') {
        window.$msg('保存成功')
        this.$emit('updateView')
        this.close()
      }
    },
    deleteInter(index) {
      this.dataList.splice(index, 1)
    }

  }
}

</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";

.deleteClass {
  border:none;
  color: red;
  font-size: 18px;
  margin-left: 20px;
}
.dia-tit {
  padding-bottom: 3px;
}

.el-inp-fm {
  // position: absolute;

  width: 30px;
  padding: 0px !important;
  margin-left: 5px;
  margin-right: 5px;
}

.infinite-list {
  position: relative;
  margin-bottom: 10px;

  i {
    position: absolute;
    left: 5px;
    top: 10px;
    font-size: 16px;
  }
}

.el-dialog__body {
  padding-top: 15px !important;
}

/deep/ .el-input__inner {
  padding: 0 5px !important;
}

.interdialog-btn {
  border-top: 1px solid #909399;
  padding-top: 10px;
  padding-left: 550px;
}
</style>
