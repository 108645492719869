<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="任务上传：">
      <div  v-if="currentVideoContent !== null && currentVideoContent !== undefined && changeBookFlag">
        <el-button size="small" type="primary" @click="changeBook" class="dialog-btn">更新视频</el-button>
      </div>
      <div style="display: flex;" v-else>
        <el-upload
          :auto-upload="false"
          drag
          :before-remove="beforeRemove"
          :file-list="fileListArray"
          :on-change="onFileChange"
          :headers  = "headers"
          :on-success="handleAvatarVideoSuccess"
          ref="uploadVideoFile"
          :action="uploadVideoUrl"
          class="upload-demo"
          name="file"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将视频拖到此处，或<em>点击上传</em></div>
<!--          <el-button size="small" slot="trigger" type="primary">选取视频</el-button>-->
<!--          <el-button-->
<!--            @click="uploadCheck()"-->
<!--            size="small"-->
<!--            style="margin-left: 10px;"-->
<!--            type="success"-->
<!--          >上传到服务器</el-button>-->
        </el-upload>
      </div>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition">
      至少观看&nbsp;
      <el-input-number v-model="formData.completeCondition" :min="1"></el-input-number>&nbsp;分钟
    </el-form-item>
  </el-form>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import { addTask, editTask } from '@/api/research/curriculum'
import { uploadCheck } from '@/api/video/videomanage'
import SparkMD5 from "spark-md5"

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    headers:{
      default: () => {
        return {
          Authorization:""
        }
      },
      type: Object
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { Tinymce},
  data () {
    return {
      changeBookFlag:true,
      fileListArray: [],
      uploadVideoUrl:process.env.VUE_APP_API_ADDRESS + '/video/upload',
      // uploadVideoUrl: process.env.NODE_ENV === 'development' ? '/api/file/upload' : process.env.VUE_APP_VIDEO_API_ADDRESS + '/file/upload',
      hash:"",
      currentVideoContent:'',
      formData: {
        title: '',
        sourceContent: '',
        completeCondition: 1,
        teacherPlan:'',
        lecturePurpose:false,
        learnPurpose:false,
        practicePurpose:false,
      },
      rules: {
        title: [{ required: true, message: '请填写任务标题', trigger: 'blur' }],
      },
      uploadUrl: process.env.NODE_ENV === 'development' ? '/api/common/file/uploadPdf' : process.env.VUE_APP_API_ADDRESS + 'common/file/uploadPdf',
      fileList: []
    }
  },

  methods: {

    changeBook(){
      this.changeBookFlag = false
    },
    updateViewData() {
      this.currentVideoContent = this.formData.sourceId
      this.changeBookFlag = true
    },

    onFileChange (file, fileList) {
      let arr = file.name.split('.')
      if (arr && arr.length > 1) {
        let suffixName = arr[arr.length - 1].toLowerCase()
        if (suffixName !== 'mp4' && suffixName !== 'rmvb'&& suffixName !== 'flv'&& suffixName !== 'avi'&& suffixName !== 'wmv') {
          this.$message.error('文件类型错误，请重新上传！')
          this.fileListArray = []
          return false
        }
      }
      this.fileListArray = fileList.slice(-1);
      let _this = this
      // element 中组件对 file 进行加工，这里使用未加工的对象，只有未加工的对象才能在 blobSlice.call() 中正常操作
      let fileRaw = file.raw

      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let fileReader = new FileReader()
      let spark = new SparkMD5()
      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result)
        _this.hash = spark.end()
      }
      fileReader.onerror = function () {
        console.warn('FileReader error.')
      }
      fileReader.readAsBinaryString(blobSlice.call(fileRaw, 0, 1024*1024))
      setTimeout(async ()=> {
        const res = await uploadCheck({
          hash: _this.hash
        })
        if(res.state== "success"){
          if(!res.body) {
            _this.$refs.uploadVideoFile.submit()
          }else {
            window.$msg('文件上传成功')
            _this.currentVideoContent = res.body
          }
        }
      },1000)
    },

    handleAvatarVideoSuccess(res,file) {
      if (res && res.body !== null) {
        this.currentVideoContent = res.body
        window.$msg('上传成功')
      } else {
        window.$msg('上传文件失败', 2)
      }
    },

    beforeRemove (file, fileList) {
      return  this.$confirm(`确定移除 ${file.name}？`).then(()=>{
        this.fileListArray = []
        return true
      })
    },

    addTask () {
      // let _this = this
      //
      // this.$refs['formData'].validate(valid => {
      //   if (valid) {
      //     addTask({
      //       type: '视频',
      //       courseUnitId: this.lessonId,
      //       title: this.formData.title,
      //       completeCondition: this.formData.completeCondition,
      //       sourceContent: this.formData.sourceContent,
      //       teacherPlan:this.formData.teacherPlan,
      //       lecturePurpose:this.formData.lecturePurpose ? 'Y' : 'N',
      //       learnPurpose:this.formData.learnPurpose ? 'Y' : 'N',
      //       practicePurpose:this.formData.practicePurpose ? 'Y' : 'N',
      //     }).then(res => {
      //       if (res.state === 'success') {
      //         _this.fileListArray = []
      //         window.$msg('添加成功')
      //         this.$emit('updateTask',res)
      //       }
      //     })
      //   }
      // })
    },

    editTask () {
      let _this = this
      if(this.currentVideoContent === "") {
        $msg("请先上传视频文件",2)
        return false
      }
      this.$refs['formData'].validate(valid => {
        if (valid) {
          editTask({
            id: this.editTaskData.id,
            title: this.formData.title, // 标题
            sourceContent: this.currentVideoContent, // 资源内容
            completeCondition: this.formData.completeCondition, // 任务完成条件
          }).then(res => {
            if (res.state === 'success') {
              _this.fileListArray = []
              window.$msg('编辑成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    }
  }
}
</script>
