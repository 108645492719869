import { getCatalogTree, getCourseList } from '@/api/research/curriculum'

export default {
  data () {
    return {
      tableArr: [],
      cascaderList: [],
      CouresList: [], // 课程列表
      CuriData: [] // 合并数据
    }
  },
  mounted () {
    this.courseCatalog()
  },
  methods: {
    // 获取课程类别
    async courseCatalog () {
      this.tableArr = []
      this.cascaderList = []
      const res = await getCatalogTree({
        group:"编程"
      })
      this.cascaderList = res.body
      this.getCouresList()
    },

    // 获取课程
    async getCouresList () {
      this.CouresList = []
      const res = await getCourseList({
        pageNum: 1,
        pageSize: 1000
      })
      for (let i = 0; i < res.body.list.length; i++) {
        if (res.body.list[i].status === '已发布') {
          this.CouresList.push(res.body.list[i])
        }
      }
      // 处理数据
      this.mergeData()
    },

    // 合并数据
    mergeData () {
      let CuriData = JSON.stringify(this.CouresList)
      CuriData = CuriData.replace(/"catalogId"/g, '"pid"').replace(/"title"/g, '"name"')
      this.CuriData = JSON.parse(CuriData)
      let a = this.cascaderList.concat(this.CuriData)
      this.buildTree(a)
    },

    // 构建 树形数据
    buildTree (list) {
      let dataList = []
      for (let index in list) {
        dataList[list[index].id] = list[index]
      }
      for (let i in dataList) {
        if (dataList[i].pid !== undefined && dataList[i].pid !== "") {
          if(dataList[dataList[i].pid] === undefined) {
            dataList[dataList[i].pid] = {}
          }
          if (dataList[dataList[i].pid].children === undefined) {
            dataList[dataList[i].pid].children = []
          }
          dataList[dataList[i].pid].children.push(dataList[i])
        } else {
          this.tableArr.push(dataList[i])
        }
      }
      // console.log('this.tableArr', JSON.stringify(this.tableArr))
    }
  }
}
