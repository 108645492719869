var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "任务内容：", prop: "description" } },
        [
          _c("Tinymce", {
            key: _vm.formData.id,
            ref: "tinymceref",
            attrs: { width: "100%", height: 350 },
            model: {
              value: _vm.formData.description,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "description", $$v)
              },
              expression: "formData.description",
            },
          }),
        ],
        1
      ),
      _vm.formData.type !== ""
        ? _c(
            "el-form-item",
            { attrs: { label: "关联模板：", prop: "sourceId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "250px" },
                  attrs: {
                    filterable: "",
                    placeholder: "请关联和任务相关的模板",
                  },
                  on: { "visible-change": () => _vm.scratchmoduleList("") },
                  model: {
                    value: _vm.formData.sourceId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "sourceId", $$v)
                    },
                    expression: "formData.sourceId",
                  },
                },
                _vm._l(_vm.moduleArray, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.title + "  [" + item.name + "]",
                      value: item.id,
                    },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  staticStyle: { "margin-left": "30px" },
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.add },
                },
                [_vm._v("新增模板")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "完成条件：", prop: "completeCondition" } },
        [_c("span", [_vm._v("完成上传作品")])]
      ),
      _c(
        "el-form-item",
        [
          _c("span", { attrs: { slot: "label" }, slot: "label" }, [
            _vm._v("默认开放："),
          ]),
          _c("el-switch", {
            attrs: {
              "active-color": "#13ce66",
              "inactive-color": "#ff4949",
              "active-text": "开",
              "inactive-text": "关",
            },
            model: {
              value: _vm.formData.defOpen,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "defOpen", $$v)
              },
              expression: "formData.defOpen",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "learnPurpose" } },
        [
          _c(
            "span",
            { attrs: { slot: "label" }, slot: "label" },
            [
              _vm._v("学员任务： "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: "标识此任务是否在学生端可见",
                    effect: "dark",
                    placement: "top",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-warning-outline",
                    staticStyle: { "font-weight": "bold" },
                  }),
                ]
              ),
            ],
            1
          ),
          _c("el-switch", {
            attrs: { "active-text": "是", "inactive-text": "否" },
            model: {
              value: _vm.formData.learnPurpose,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "learnPurpose", $$v)
              },
              expression: "formData.learnPurpose",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "practicePurpose" } },
        [
          _c(
            "span",
            { attrs: { slot: "label" }, slot: "label" },
            [
              _vm._v("课后练习： "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: "标识此任务是否可以作为课堂练习在加盟学生端使用",
                    effect: "dark",
                    placement: "top",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-warning-outline",
                    staticStyle: { "font-weight": "bold" },
                  }),
                ]
              ),
            ],
            1
          ),
          _c("el-switch", {
            attrs: { "active-text": "是", "inactive-text": "否" },
            model: {
              value: _vm.formData.practicePurpose,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "practicePurpose", $$v)
              },
              expression: "formData.practicePurpose",
            },
          }),
        ],
        1
      ),
      _c("add-scratch-modle", {
        attrs: { show: _vm.addMoudleDialog, title: _vm.title },
        on: {
          close: function ($event) {
            _vm.addMoudleDialog = false
          },
          scratchmoduleList: _vm.scratchmoduleList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }