var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "edit-style-box",
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.uploadTitle) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            "label-width": "120px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "任务标题：", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写任务标题", maxlength: "32" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          false
            ? _c(
                "el-form-item",
                { attrs: { label: "课件来源：", prop: "onlinePpt" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请填写课件来源" },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.formData.onlinePpt,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "onlinePpt",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.onlinePpt",
                      },
                    },
                    _vm._l(_vm.option, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.formData.onlinePpt === "Y" && _vm.type === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "在线课件：", required: "" } },
                [
                  _vm.currentWareContent
                    ? _c("span", [_vm._v("已选择")])
                    : _c("span", [_vm._v("未选择")]),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.goCoursewareEdit },
                    },
                    [_vm._v("编辑")]
                  ),
                ],
                1
              )
            : _vm._e(),
          (_vm.formData.onlinePpt !== "N" && _vm.type !== 1) ||
          (_vm.type === 1 && _vm.formData.onlinePpt !== "Y")
            ? _c(
                "el-form-item",
                { attrs: { label: "任务上传：", required: "" } },
                [
                  _vm.formData.content !== null &&
                  _vm.formData.content !== undefined &&
                  _vm.changeBookFlag
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "dialog-btn",
                              attrs: { type: "primary" },
                              on: { click: _vm.changeBook },
                            },
                            [_vm._v("更换授课资料")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _vm.type === 1
                            ? _c(
                                "el-upload",
                                {
                                  ref: "uploadCourseFile",
                                  attrs: {
                                    drag: "",
                                    "before-remove": _vm.beforeRemove,
                                    "file-list": _vm.fileCourseList,
                                    "before-upload": _vm.beforeAvatarUpload,
                                    "on-success": _vm.handleAvatarSuccess,
                                    "on-change": _vm.fileCourseChange,
                                    headers: _vm.headers,
                                    accept: ".zip",
                                    action: _vm.uploadUrl,
                                    name: "file",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c(
                                    "div",
                                    { staticClass: "el-upload__text" },
                                    [
                                      _vm._v(" 将文件拖到此处，或"),
                                      _c("em", [_vm._v("点击上传")]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        " 只能上传zip格式，且文件名不能包含中文，上传文件不能超过 200MB! "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "el-upload",
                                {
                                  ref: "uploadVideoFile",
                                  staticClass: "upload-demo",
                                  attrs: {
                                    "auto-upload": false,
                                    drag: "",
                                    "before-remove": _vm.beforeRemove,
                                    "file-list": _vm.fileVideoList,
                                    "on-change": _vm.fileVideoChange,
                                    headers: _vm.headers,
                                    "on-success": _vm.handleAvatarVideoSuccess,
                                    action: _vm.uploadVideoUrl,
                                    name: "file",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c(
                                    "div",
                                    { staticClass: "el-upload__text" },
                                    [
                                      _vm._v(" 将视频拖到此处，或"),
                                      _c("em", [_vm._v("点击上传")]),
                                    ]
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveUpload()
                },
              },
            },
            [_vm._v("保 存")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
      _vm.editShow
        ? _c(
            "el-dialog",
            {
              staticClass: "edit-style",
              staticStyle: { "z-index": "9999" },
              attrs: { fullscreen: true, visible: _vm.editShow, modal: false },
              on: {
                "update:visible": function ($event) {
                  _vm.editShow = $event
                },
              },
            },
            [_c("edit-modal", { on: { setUseWareId: _vm.setUseWareId } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }