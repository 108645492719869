<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="任务上传：" required>
      <div  v-if="formData.content !== null && formData.content !== undefined && changeBookFlag">
        <el-button type="primary" @click="changeBook" class="dialog-btn">更换授课资料</el-button>
      </div>
      <div style="display: flex;" v-else>
        <el-upload
          drag
          :before-remove="beforeRemove"
          :file-list="fileCourseList"
          :before-upload="beforeAvatarUpload"
          :on-success="handleAvatarSuccess"
          :on-change="fileCourseChange"
          :headers="headers"
          ref="uploadCourseFile"
          accept=".zip"
          :action="uploadUrl"
          name="file"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传zip格式，且文件名不能包含中文，上传文件不能超过 200MB!</div>
        </el-upload>
      </div>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition">
      至少观看&nbsp;
      <el-input-number v-model="formData.completeCondition" :min="1"></el-input-number>&nbsp;分钟
    </el-form-item>
  </el-form>
</template>

<script>
import {getToken} from '@/utils/auth'
import {addAttachment ,resetAttachment,editTask} from '@/api/research/curriculum'

export default {
  props: {
    editUploadData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  data() {
    return {
      hash:'',
      changeBookFlag:true,
      currentWareContent: '',
      headers: {Authorization: ""},
      uploadForm: null,
      formData: {
        title: '',
        completeCondition: 1,
      },
      rules: {
        title: [{required: true, message: '请填写任务标题', trigger: 'blur'}],
      },
      fileCourseList: [],
      uploadUrl: process.env.VUE_APP_API_ADDRESS + 'courseUnit/uploadAttachment',
    }
  },

  methods: {
    changeBook(){
      this.changeBookFlag = false
    },
    async saveUpload() {
      this.$refs['formData'].validate(async valid => {
        if (valid) {
          if(this.currentWareContent === '') {
            window.$msg('请先上传课件',2)
            return false
          }
          this.uploadForm = new FormData()
          this.uploadForm.append("taskId", this.editUploadData.id)
          this.uploadForm.append('title', this.formData.title)
          this.uploadForm.append('content', this.currentWareContent)
          this.uploadForm.append('type', '课件')
          if(this.editUploadData.sourceContent === null) {
            await addAttachment(this.uploadForm)
          }else {
            await resetAttachment(this.uploadForm)
          }
        }
      })
    },

    initData() {
      this.changeBookFlag = true
      this.headers.Authorization = getToken()
      this.formData = Object.assign({}, this.editUploadData)
      this.currentWareContent = this.editUploadData.content
      this.fileCourseList = []
      this.currentWareContent = ''
      this.$refs['formData'].resetFields()
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 200
      if (!isLt2M) {
        window.$msg('上传文件不能超过 200MB!', 1)
      }
      return isLt2M
    },
    async handleAvatarSuccess(res, file, fileList) {
      if (res && res.state === 'success') {
        this.currentWareContent = res.body
        window.$msg('课件上传成功')
      } else {
        window.$msg('上传文件失败', 2)
      }
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },

    fileCourseChange(file, fileList) {
      this.fileCourseList = fileList.splice(-1)
    },

    editTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          editTask({
            id: this.editUploadData.id,
            title: this.formData.title, // 标题
            completeCondition: this.formData.completeCondition, // 任务完成条件
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('编辑成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    }
  }
}
</script>
