import { render, staticRenderFns } from "./addCourseware.vue?vue&type=template&id=a27b5646&scoped=true"
import script from "./addCourseware.vue?vue&type=script&lang=js"
export * from "./addCourseware.vue?vue&type=script&lang=js"
import style0 from "./addCourseware.vue?vue&type=style&index=0&id=a27b5646&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a27b5646",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/frontend_coduck_manage_sbGx/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a27b5646')) {
      api.createRecord('a27b5646', component.options)
    } else {
      api.reload('a27b5646', component.options)
    }
    module.hot.accept("./addCourseware.vue?vue&type=template&id=a27b5646&scoped=true", function () {
      api.rerender('a27b5646', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/research/curriculum-manage/addCourseware.vue"
export default component.exports