<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>课件视频管理</span>
    </div>
    <!-- isSort：{{isSort}} -->
    <!-- coursewareList: {{coursewareList}}
    <hr>
    oldList：{{oldList}}
    <hr>
    newList：{{newList}} -->
    <el-table :data="coursewareList" border fit highlight-current-row>
      <el-table-column
        label="名称"
        align="center"
        prop="title"
      ></el-table-column>

      <el-table-column
        label="类型"
        align="center"
        prop="type"
      ></el-table-column>

      <el-table-column label="创建日期" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.row.createTime.substring(0, scope.row.createTime.length - 5)
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.status }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            plain
            @click="viewCourseWare(scope.row)"
            >查看</el-button
          >
          <el-button
            size="small"
            type="primary"
            plain
            @click="deleteCourseWare(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-dialog>
</template>

<script>
import { getListAttachment, delAttachment } from "@/api/research/curriculum";
import { getVideoUrl } from "@/api/video/videomanage";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    applyCourseData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      courseUnitId: "",
      resetUse: {},
      coursewareList: [],
      isSort: false,
      SortText: "",
      type: "",
      typeOptions: [
        {
          value: "课件",
          label: "课件",
        },
        {
          value: "补课视频",
          label: "补课视频",
        },
        {
          value: "串讲视频",
          label: "串讲视频",
        },
      ],
    };
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getCourseWareList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getCourseWareList();
    },

    openDialog() {
      this.type = "课件";
      this.isSort = false;
      if (!this.isSort) {
        this.SortText = "课件视频排序";
      } else {
        this.SortText = "退出排序";
      }
      this.courseUnitId = this.applyCourseData.id;
      this.getCourseWareList();
    },

    // dateKey 时间处理
    datefmt(date) {
      let res = "";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      res = `${year}-${month}-${day}`;
      return res;
    },

    async getCourseWareList() {
      const res = await getListAttachment({
        taskId: this.courseUnitId,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      });
      this.pagination.total = res.body.total;
      this.coursewareList = res.body.list;
    },

    close() {
      this.$emit("close");
    },

    viewCourseWare(row) {
      if (row.type === "课件") {
        let routeData = this.$router.resolve({
          path: "/research/preview-accessory",
          query: {
            title: row.title,
            sourceContent: row.content,
            id: row.id,
          },
        });
        window.open(routeData.href, "_blank");
      } else if (row.type === "补课视频" || row.type === "串讲视频") {
        let routeData = this.$router.resolve({
          path: "/research/preview-video",
          query: {
            sourceVideoId: row.content,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },

    async getVideoUrl(fileId) {
      const res = await getVideoUrl({
        fileId,
      });
      if (res.code === 1) {
        window.$msg(res.msg, 2);
      } else if (res.code === 0) {
        let routeData = this.$router.resolve({
          path: "/research/preview-video",
          query: {
            pathUrl: res.body,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },

    // 删除课件
    deleteCourseWare(row) {
      this.$confirm("是否删除当前文件", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await delAttachment({ id: row.id });
        window.$msg("删除成功");
        this.getCourseWareList();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>>
