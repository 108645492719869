<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules" style="padding-right: 80px">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <div v-for="(item,index) in topicList" :key='index' style="display: flex;align-items: center;width: 100%">
      <div>
        <el-form-item label="任务说明：">
          <Tinymce ref="tinymcerefDes" :key="item.id" v-model="item.description" width="100%" :height="350"/>
        </el-form-item>
        <el-form-item label="任务内容：">
          <Tinymce ref="tinymceref" :key="item.id" v-model="item.content" width="100%" :height="350"/>
        </el-form-item>
      </div>
      <i class="el-icon-circle-plus-outline iconStyle" @click="plusClick" v-if="index === 0" ></i>
      <i class="el-icon-remove-outline iconStyle" @click="removeClick(index)" v-else ></i>
    </div>
    <el-form-item label="完成条件：" prop="completeCondition">
      至少观看&nbsp;
      <el-input-number v-model="formData.completeCondition" :min="1"></el-input-number>&nbsp;分钟
    </el-form-item>
  </el-form>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import {getTaskTopicList, editTask} from '@/api/research/curriculum'

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {Tinymce},

  methods: {
    removeClick(index) {
      this.topicList.splice(index,1)
    },
    plusClick() {
      this.topicList.push(
        {
          id:Math.round(Math.random()*100000),
          batch:null,
          description:'',
          content:''
        }
      )
    },
    updateData() {
      this.topicList.forEach((item,index) => {
        this.$refs.tinymceref[index].setContent(item.content)
        this.$refs.tinymcerefDes[index].setContent(item.description)
      })
    },
  },
  data() {
    let RichTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('请填写任务内容'))
      } else {
        callback()
      }
    }
    return {
      topicList: [{
        id:Math.round(Math.random()*100000),
        batch:null,
        description:'',
        content:''
      }],
      formData: {
        title: '',
        completeCondition: 1,
        teacherPlan: '',
        lecturePurpose: false,
        learnPurpose: false,
        practicePurpose: false,
      },
      rules: {
        title: [{required: true, message: '请填写任务标题', trigger: 'blur'}],
        sourceContent: [
          {required: true, validator: RichTextVal, trigger: 'blur'}
        ]
      },

      editTask() {
        this.$refs['formData'].validate(valid => {
          if (valid) {
            let contentFlag = false
            this.topicList.forEach(item => {
              if(item.content === ''){
                contentFlag = true
              }
            })

            if(contentFlag) {
              window.$msg('请完成所有题目的任务内容填写',2)
              return false
            }

            editTask({
              id: this.editTaskData.id,
              title: this.formData.title, // 标题
              // sourceContent: this.formData.sourceContent, // 资源内容
              taskItems:this.topicList,
              completeCondition: this.formData.completeCondition, // 任务完成条件
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.$emit('updateTask')
              }
            })
          }
        })
      },
      async getTaskTopicList() {
        const res = await getTaskTopicList({
          taskId:this.editTaskData.id
        })
        this.topicList = res.body

        if(this.topicList.length === 0) {
          this.topicList =  [{
            id:Math.round(Math.random()*100000),
            batch:null,
            description:'',
            content:''
          }]
          this.$forceUpdate()
        }else {
          this.topicList.forEach(item => {
            item.id = Math.round(Math.random()*100000)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.iconStyle {
  margin-left: 15px;
  font-size: 24px;
}
</style>
