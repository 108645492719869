var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.lessonTitle) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课节名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写课节名称" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _vm.CourseInfo.courseGroup === "编程"
            ? _c(
                "el-form-item",
                { attrs: { prop: "standardMinutes" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("上课时长： "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "设置本节课排课的上课时长",
                            placement: "top",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-warning-outline",
                            staticStyle: { "font-weight": "bold" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "请填写上课时长",
                      precision: 0,
                      min: 1,
                      controls: false,
                    },
                    model: {
                      value: _vm.formData.standardMinutes,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "standardMinutes", $$v)
                      },
                      expression: "formData.standardMinutes",
                    },
                  }),
                  _c("span", { staticStyle: { "margin-left": "15px" } }, [
                    _vm._v("min"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveLesson()
                },
              },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }