var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("复制课程")])]
      ),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          [
            _c("span", [_vm._v("当前课程下的课节")]),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-top": "10px" },
                attrs: { data: _vm.tableData },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "55" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "title", label: "课节名称", width: "180" },
                }),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: {
                      "text-align": "left",
                      "margin-bottom": "15px",
                      "margin-top": "20px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.saveTag },
                      },
                      [_vm._v("保 存")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "info", size: "small" },
                        on: { click: _vm.close },
                      },
                      [_vm._v("取 消")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticStyle: { "margin-left": "32px" } }, [
          _c("span", [_vm._v("选择要复制到的课程")]),
          _c(
            "div",
            [
              _c("el-cascader", {
                ref: "cascader",
                staticStyle: { width: "100%", "margin-top": "10px" },
                attrs: {
                  clearable: "",
                  placeholder: "请选择要复制到的课程",
                  "show-all-levels": false,
                  options: _vm.tableArr,
                  props: { value: "id", label: "name" },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return [
                        data.type === undefined
                          ? _c("span", [
                              _c("i", {
                                staticClass: "el-icon-folder folderClass",
                              }),
                            ])
                          : _c("span", [
                              _c("i", {
                                staticClass: "el-icon-tickets ticketsClass",
                              }),
                            ]),
                        _c("span", [_vm._v(_vm._s(data.name))]),
                        !node.isLeaf
                          ? _c("span", [
                              _vm._v(
                                " (" + _vm._s(data.children.length) + ") "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.courseId,
                  callback: function ($$v) {
                    _vm.courseId = $$v
                  },
                  expression: "courseId",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }