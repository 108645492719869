var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.interactiveTitle) },
          }),
        ]
      ),
      _c(
        "el-alert",
        {
          staticClass: "infinite-list",
          attrs: {
            title:
              "互动视频能够使老师在课堂中使用授课大屏播放视频自动触发特定事件（仅限于启用【互动课堂】功能时使用)",
            type: "warning",
            closable: false,
          },
        },
        [_c("i", { staticClass: "el-icon-info infinite-i" })]
      ),
      _c(
        "el-form",
        { attrs: { "label-width": "80px" } },
        [
          _c("el-form-item", {
            attrs: { label: "事件配置:" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "ul",
                      {
                        staticClass: "list",
                        staticStyle: { "list-style": "none" },
                      },
                      _vm._l(_vm.dataList, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              height: "50px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "inline-block" } },
                              [_vm._v(_vm._s(index + 1) + ".视频播放到")]
                            ),
                            _c("el-input", {
                              staticClass: "el-inp-fm",
                              attrs: { disabled: "", placeholder: "分" },
                              model: {
                                value: item.minite,
                                callback: function ($$v) {
                                  _vm.$set(item, "minite", $$v)
                                },
                                expression: "item.minite",
                              },
                            }),
                            _vm._v("分 : "),
                            _c("el-input", {
                              staticClass: "el-inp-fm",
                              attrs: { disabled: "", placeholder: "秒" },
                              model: {
                                value: item.second,
                                callback: function ($$v) {
                                  _vm.$set(item, "second", $$v)
                                },
                                expression: "item.second",
                              },
                            }),
                            _vm._v("秒， "),
                            item.eventType === "推送"
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { display: "inline-block" },
                                    },
                                    [
                                      _vm._v("推送任务 "),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "280px" },
                                          attrs: {
                                            disabled: "",
                                            placeholder: "请选择要推送的任务",
                                          },
                                          model: {
                                            value: item.pushTaskId,
                                            callback: function ($$v) {
                                              _vm.$set(item, "pushTaskId", $$v)
                                            },
                                            expression: "item.pushTaskId",
                                          },
                                        },
                                        _vm._l(_vm.taskList, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label:
                                                item.title +
                                                " [" +
                                                item.type +
                                                "]",
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : item.eventType === "结束推送"
                              ? _c("div", [_vm._v(" 结束任务推送 ")])
                              : item.eventType === "暂停"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { width: "140px" } },
                                      [_vm._v("暂停视频，弹窗提示")]
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "250px" },
                                      attrs: {
                                        disabled: "",
                                        type: "textarea",
                                        rows: 2,
                                        maxlength: "50",
                                        placeholder: "输入弹窗内容，留空不弹",
                                      },
                                      model: {
                                        value: item.pushRemark,
                                        callback: function ($$v) {
                                          _vm.$set(item, "pushRemark", $$v)
                                        },
                                        expression: "item.pushRemark",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }