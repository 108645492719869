var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      staticStyle: { "padding-right": "80px" },
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.topicList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticStyle: {
              display: "flex",
              "align-items": "center",
              width: "100%",
            },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "任务说明：" } },
                  [
                    _c("Tinymce", {
                      key: item.id,
                      ref: "tinymcerefDes",
                      refInFor: true,
                      attrs: { width: "100%", height: 350 },
                      model: {
                        value: item.description,
                        callback: function ($$v) {
                          _vm.$set(item, "description", $$v)
                        },
                        expression: "item.description",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "任务内容：" } },
                  [
                    _c("Tinymce", {
                      key: item.id,
                      ref: "tinymceref",
                      refInFor: true,
                      attrs: { width: "100%", height: 350 },
                      model: {
                        value: item.content,
                        callback: function ($$v) {
                          _vm.$set(item, "content", $$v)
                        },
                        expression: "item.content",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            index === 0
              ? _c("i", {
                  staticClass: "el-icon-circle-plus-outline iconStyle",
                  on: { click: _vm.plusClick },
                })
              : _c("i", {
                  staticClass: "el-icon-remove-outline iconStyle",
                  on: {
                    click: function ($event) {
                      return _vm.removeClick(index)
                    },
                  },
                }),
          ]
        )
      }),
      _c(
        "el-form-item",
        { attrs: { label: "完成条件：", prop: "completeCondition" } },
        [
          _vm._v(" 至少观看  "),
          _c("el-input-number", {
            attrs: { min: 1 },
            model: {
              value: _vm.formData.completeCondition,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "completeCondition", $$v)
              },
              expression: "formData.completeCondition",
            },
          }),
          _vm._v(" 分钟 "),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }