var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.topicList, function (item, index) {
        return _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              width: "100%",
            },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "任务内容：" } },
                  [
                    _c("Tinymce", {
                      key: item.id,
                      ref: "tinymceref",
                      refInFor: true,
                      attrs: { width: "100%", height: 350 },
                      model: {
                        value: item.description,
                        callback: function ($$v) {
                          _vm.$set(item, "description", $$v)
                        },
                        expression: "item.description",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "关联模板：" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "250px" },
                        attrs: {
                          filterable: "",
                          placeholder: "请关联和任务相关的模板",
                        },
                        model: {
                          value: item.content,
                          callback: function ($$v) {
                            _vm.$set(item, "content", $$v)
                          },
                          expression: "item.content",
                        },
                      },
                      _vm._l(_vm.moduleArray, function (value) {
                        return _c("el-option", {
                          key: value.id,
                          attrs: {
                            label:
                              value.title + "  [" + value.creatorName + "]",
                            value: value.id,
                          },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "tb-button",
                        staticStyle: { "margin-left": "30px" },
                        attrs: { type: "primary", size: "medium" },
                        on: { click: _vm.add },
                      },
                      [_vm._v("新增模板")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            index === 0
              ? _c("i", {
                  staticClass: "el-icon-circle-plus-outline iconStyle",
                  on: { click: _vm.plusClick },
                })
              : _c("i", {
                  staticClass: "el-icon-remove-outline iconStyle",
                  on: {
                    click: function ($event) {
                      return _vm.removeClick(index)
                    },
                  },
                }),
          ]
        )
      }),
      _c(
        "el-form-item",
        { attrs: { label: "完成条件：", prop: "completeCondition" } },
        [_c("span", [_vm._v("完成上传作品")])]
      ),
      _c("add-scratch-modle", {
        attrs: { show: _vm.addMoudleDialog },
        on: {
          close: function ($event) {
            _vm.addMoudleDialog = false
          },
          scratchmoduleList: _vm.scratchmoduleList,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }