<template>
  <el-dialog
    class="edit-style-box"
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="uploadTitle"></span>
    </div>
    <el-form
      ref="formData"
      :model="formData"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="任务标题：" prop="title">
        <el-input
          v-model.trim="formData.title"
          placeholder="请填写任务标题"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="课件来源：" prop="onlinePpt" v-if="type === 1"> -->
      <el-form-item label="课件来源：" prop="onlinePpt" v-if="false">
        <el-select
          style="width: 100%"
          v-model.trim="formData.onlinePpt"
          @change="handleChange"
          placeholder="请填写课件来源"
        >
          <el-option
            v-for="(item, index) in option"
            :key="index"
            :value="item.value"
            :label="item.label"
          ></el-option>
          <!-- <el-option key="N" value="N" label="上传课件">上传课件</el-option> -->
        </el-select>
        <!-- <el-input v-model.trim="formData.onlinePpt" placeholder="请填写课件来源" maxlength="32"></el-input> -->
      </el-form-item>
      <el-form-item v-if="formData.onlinePpt === 'Y' && type === 1" label='在线课件：' required>
        <span v-if="currentWareContent">已选择</span>
        <span v-else>未选择</span>
        <el-button type="primary" size="small" style="margin-left:10px" @click="goCoursewareEdit">编辑</el-button>
      </el-form-item>
      <el-form-item v-if="(formData.onlinePpt !== 'N' && type !== 1) || (type === 1 && formData.onlinePpt !== 'Y')" label="任务上传：" required>
        <div
          v-if="
            formData.content !== null &&
            formData.content !== undefined &&
            changeBookFlag
          "
        >
          <el-button type="primary" @click="changeBook" class="dialog-btn"
            >更换授课资料</el-button
          >
        </div>
        <div style="display: flex" v-else>
          <el-upload
            v-if="type === 1"
            drag
            :before-remove="beforeRemove"
            :file-list="fileCourseList"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
            :on-change="fileCourseChange"
            :headers="headers"
            ref="uploadCourseFile"
            accept=".zip"
            :action="uploadUrl"
            name="file"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <!--            <el-button size="small" slot="trigger" type="primary">选取文件</el-button>-->
            <!--            <el-button-->
            <!--              @click="submitUpload()"-->
            <!--              size="small"-->
            <!--              style="margin-left: 10px;"-->
            <!--              type="success"-->
            <!--            >上传到服务器-->
            <!--            </el-button>-->
            <div class="el-upload__tip" slot="tip">
              只能上传zip格式，且文件名不能包含中文，上传文件不能超过 200MB!
            </div>
          </el-upload>

          <el-upload
            v-else
            :auto-upload="false"
            drag
            :before-remove="beforeRemove"
            :file-list="fileVideoList"
            :on-change="fileVideoChange"
            :headers="headers"
            :on-success="handleAvatarVideoSuccess"
            ref="uploadVideoFile"
            :action="uploadVideoUrl"
            class="upload-demo"
            name="file"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将视频拖到此处，或<em>点击上传</em>
            </div>
            <!--            <el-button size="small" slot="trigger" type="primary">选取视频</el-button>-->
            <!--            <el-button-->
            <!--              @click="submitVideoUpload()"-->
            <!--              size="small"-->
            <!--              style="margin-left: 10px;"-->
            <!--              type="success"-->
            <!--            >上传到服务器-->
            <!--            </el-button>-->
          </el-upload>
        </div>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveUpload()" class="dialog-btn"
        >保 存</el-button
      >
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>

    </el-row>
    <el-dialog
      :fullscreen="true"
      :visible.sync="editShow"
      v-if='editShow'
      :modal="false"
      style="z-index: 9999"
      class="edit-style"
    >
      <edit-modal @setUseWareId="setUseWareId"/>
    </el-dialog>

  </el-dialog>
</template>

<script>
import { getToken } from "@/utils/auth";
import { addAttachment, resetAttachment } from "@/api/research/curriculum";
import SparkMD5 from "spark-md5";
import { uploadCheck } from "@/api/video/videomanage";
import EditModal from "@/components/courseware/index";
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    taskId: {
      default: "",
      type: String,
    },
    editFlag: {
      default: 0,
      type: Number,
    },
    type: {
      default: 0,
      type: Number,
    },
    editUploadData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: { EditModal },
  data() {
    return {
       fullscreenLoading: false,
      option: [
        { label: "在线课件", value: "Y" },
        { label: "原有课件", value: "N" },
      ],

      hash: "",
      editShow: false,
      changeBookFlag: true,
      currentWareContent: "",
      currentVideoContent: "",
      headers: { Authorization: "" },
      uploadTitle: "授课资料上传",
      uploadForm: new FormData(),
      formData: {
        title: "",
        onlinePpt: "N",
      },
      rules: {
        title: [{ required: true, message: "请填写任务标题", trigger: "blur" }],
        // onlinePpt: [
        //   { required: true, message: "请填写课件来源", trigger: "blur" },
        // ],
      },
      fileCourseList: [],
      uploadUrl:
        process.env.VUE_APP_API_ADDRESS + "courseUnit/uploadAttachment",

      fileVideoList: [],
      uploadVideoUrl: process.env.VUE_APP_API_ADDRESS + "video/upload",
    };
  },

  methods: {
    openFullScreen1() {
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.fullscreenLoading = false;
        }, 2000);
      },
    changeBook() {
      this.changeBookFlag = false;
    },
    async saveUpload() {
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          if (this.type === 1) {
            if (this.currentWareContent === "") {
              window.$msg("请先上传课件", 2);
              return false;
            }
            const currentTaskInfo = JSON.parse(localStorage.getItem("currentTaskInfo"));
            this.uploadForm.append("taskId", this.taskId);
            this.uploadForm.append("title", this.formData.title);
            this.uploadForm.append("onlinePpt", "N");
            this.uploadForm.append("courseUnitId", currentTaskInfo.courseUnitId);
            this.uploadForm.append("content", this.currentWareContent);
            this.uploadForm.append("type", "课件");
            if (this.editFlag === 1) {
              await resetAttachment(this.uploadForm);
            } else {
              await addAttachment(this.uploadForm);
            }

            window.$msg("保存成功");
            this.$emit("updateView");
            this.close();
            this.uploadForm = new FormData();
            this.fileCourseList = [];
            this.formData.title = "";
          } else {
            if (this.currentVideoContent === "") {
              window.$msg("请先上传视频", 2);
              return false;
            }
            const currentTaskInfo = JSON.parse(localStorage.getItem("currentTaskInfo"));
            let formdata = new FormData();
            formdata.append("type", this.type == 2 ? "补课视频" : "串讲视频");
            formdata.append("taskId", this.taskId);
            formdata.append("title", this.formData.title);
            formdata.append("courseUnitId", currentTaskInfo.courseUnitId);
            formdata.append("content", this.currentVideoContent);
            let response = {};
            if (this.editFlag === 1) {
              response = await resetAttachment(formdata);
            } else {
              response = await addAttachment(formdata);
            }
            if (response && response.state === "success") {
              window.$msg("保存成功");
              this.$emit("updateView");
              this.close();
              this.fileVideoList = [];
              this.formData.title = "";
            }
          }
        }
      });
    },
    openDialog() {
      this.changeBookFlag = true;
      this.headers.Authorization = getToken();
      this.formData = Object.assign({}, this.editUploadData);
      if(this.type === 1 && this.formData.content === undefined) {
        this.formData.onlinePpt = "N"
      }
      console.log(JSON.stringify(this.formData))
      switch (this.formData.type) {
        case "课件":
          this.currentWareContent = this.editUploadData.content;
          break;
        case "补课视频" || "串讲视频":
          this.currentVideoContent = this.editUploadData.content;
          break;
      }
    },
    handleChange() {
      this.$forceUpdate()
    },
    async handleAvatarVideoSuccess(res, file, fileList) {
      if (res && res.body !== null) {
        this.currentVideoContent = res.body;
      } else {
        window.$msg("上传文件失败", 2);
      }
    },

    fileVideoChange(file, fileList) {
      let arr = file.name.split(".");
      if (arr && arr.length > 1) {
        let suffixName = arr[arr.length - 1].toLowerCase();
        if (
          suffixName !== "mp4" &&
          suffixName !== "rmvb" &&
          suffixName !== "flv" &&
          suffixName !== "avi" &&
          suffixName !== "wmv"
        ) {
          this.$message.error("文件类型错误，请重新上传！");
          this.fileListArray = [];
          return false;
        }
      }
      this.fileVideoList = fileList.splice(-1);
      let _this = this;
      // element 中组件对 file 进行加工，这里使用未加工的对象，只有未加工的对象才能在 blobSlice.call() 中正常操作
      let fileRaw = file.raw;

      let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;
      let fileReader = new FileReader();
      let spark = new SparkMD5();
      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result);
        _this.hash = spark.end();
      };
      fileReader.onerror = function () {
        console.warn("FileReader error.");
      };
      fileReader.readAsBinaryString(blobSlice.call(fileRaw, 0, 1024 * 1024));
      setTimeout(async () => {
        const res = await uploadCheck({
          hash: this.hash,
        });
        if (res.state === "success") {
          if (!res.body) {
            this.$refs.uploadVideoFile.submit();
          } else {
            window.$msg("文件上传成功");
            this.currentVideoContent = res.body;
          }
        }
      }, 1000);
    },

    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        window.$msg("上传文件不能超过 200MB!", 1);
      }
      return isLt2M;
    },
    async handleAvatarSuccess(res, file, fileList) {
      if (res && res.state === "success") {
        this.currentWareContent = res.body;
        window.$msg("课件上传成功");
      } else {
        window.$msg(res.errMsg, 2);
      }
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // submitUpload() {
    //   this.$refs['formData'].validate(valid => {
    //     if (valid) {
    //       if (this.formData.title === '') {
    //         window.$msg('请填写课件标题后再上传', 2)
    //         return false
    //       } else if (this.fileCourseList.length === 0) {
    //         $msg("请选择课件后再上传", 2)
    //         return false
    //       }
    //       this.$refs.uploadCourseFile.submit()
    //     }
    //   })
    // },
    fileCourseChange(file, fileList) {
      this.fileCourseList = fileList.splice(-1);
    },
    close() {
      this.fileCourseList = [];
      this.fileVideoList = [];
      this.currentWareContent = "";
      this.currentVideoContent = "";
      this.$refs["formData"].resetFields();
      this.$emit("close");
    },

    /**
     * 进入在线课件制作
     */
    goCoursewareEdit() {
      this.editShow = true;
    },
    setUseWareId(id) {
      this.currentWareContent = id;
    },
  },
};
</script>
<style lang="scss">
.edit-style-box{
  .edit-style{

  }
}
.el-upload-list__item.is-success .el-upload-list__item-status-label {
  display: none !important;
}
</style>
