var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c("h3", { staticClass: "tab-title" }, [_vm._v("基本信息")]),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "所属分组：", prop: "courseGroup" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择所属分组" },
                  on: { change: _vm.courseGroupChange },
                  model: {
                    value: _vm.formData.courseGroup,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "courseGroup", $$v)
                    },
                    expression: "formData.courseGroup",
                  },
                },
                _vm._l(_vm.courseGroupList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属分类：", prop: "catalogId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择所属分类" },
                  model: {
                    value: _vm.formData.catalogId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "catalogId", $$v)
                    },
                    expression: "formData.catalogId",
                  },
                },
                _vm._l(_vm.catalogIdList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程名称：", prop: "title" } },
            [
              _c("el-input", {
                staticClass: "tab-input",
                attrs: {
                  disabled: _vm.CourseInfo.isCopy === "Y",
                  placeholder: "请输入课程名称（输入课程名称不可重复）",
                },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程昵称：" } },
            [
              _c("el-input", {
                staticClass: "tab-input",
                attrs: {
                  disabled: _vm.CourseInfo.isCopy === "Y",
                  placeholder: "请输入课程昵称（显示给购买课程的学员的名称）",
                },
                model: {
                  value: _vm.formData.nick,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "nick", $$v)
                  },
                  expression: "formData.nick",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程类型：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择课程类型" },
                  model: {
                    value: _vm.formData.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type",
                  },
                },
                _vm._l(
                  _vm.formData.courseGroup == "培训"
                    ? _vm.typeLists
                    : _vm.typeList,
                  function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }
                ),
                1
              ),
            ],
            1
          ),
          _vm.formData.courseGroup === "编程"
            ? _c(
                "el-form-item",
                { attrs: { label: "课程账号类型：", prop: "series" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择课程账号类型" },
                      on: { change: _vm.handleSelectChange },
                      model: {
                        value: _vm.formData.series,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "series", $$v)
                        },
                        expression: "formData.series",
                      },
                    },
                    _vm._l(_vm.cascaderList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.courseGoodsName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "has",
              rawName: "v-has",
              value: "curriculum:ManageEdit",
              expression: "'curriculum:ManageEdit'",
            },
          ],
          staticStyle: { margin: "35px" },
          attrs: { size: "medium", type: "primary" },
          on: { click: _vm.editCurriculum },
        },
        [_vm._v("保存")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }