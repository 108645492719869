var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticStyle: { "padding-bottom": "25px" } },
    [
      _c("h3", { staticClass: "tab-title" }, [_vm._v("课程计划")]),
      _c(
        "el-row",
        { staticClass: "task-listcurr" },
        [
          _c(
            "span",
            [
              _vm._v("总课次："),
              _c("font", {
                staticClass: "taskNum",
                domProps: { textContent: _vm._s(_vm.LessonNum) },
              }),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "task-span" },
            [
              _vm._v("总任务数："),
              _c(
                "font",
                {
                  staticClass: "taskNum",
                  domProps: { textContent: _vm._s(_vm.TaskNum) },
                },
                [_vm._v("5")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "task-rightcurr task-cursor",
              attrs: { type: "text" },
              on: { click: _vm.copyCur },
            },
            [_vm._v("复制课节")]
          ),
          _c(
            "el-button",
            {
              staticClass: "task-rightcurr task-cursor",
              attrs: { disabled: _vm.btndisable, type: "text" },
              on: { click: _vm.addLesson },
            },
            [_vm._v("新增课节")]
          ),
          _c("div", { staticClass: "clearfix" }),
          _c(
            "draggable",
            _vm._b(
              {
                staticStyle: { "padding-left": "20px" },
                attrs: {
                  reverse: _vm.reverse,
                  handle: ".el-icon-rank",
                  tag: "el-collapse",
                  move: _vm.getdata,
                },
                on: { end: _vm.datadragEnd },
                model: {
                  value: _vm.curriculumArr,
                  callback: function ($$v) {
                    _vm.curriculumArr = $$v
                  },
                  expression: "curriculumArr",
                },
              },
              "draggable",
              _vm.dragOptionsMneu,
              false
            ),
            _vm._l(_vm.curriculumArr, function (item, index) {
              return _c(
                "el-collapse",
                {
                  key: index,
                  attrs: { id: item.id },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { name: index } },
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "已发布",
                                placement: "top",
                              },
                            },
                            [
                              item.status === "已启用"
                                ? _c("i", {
                                    staticClass:
                                      "el-icon-circle-check iconflag",
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "未发布",
                                placement: "top",
                              },
                            },
                            [
                              item.status === "已禁用"
                                ? _c("i", {
                                    staticClass:
                                      "el-icon-circle-close iconflagred",
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              "第" +
                                _vm._s(Number(Number(index) + 1)) +
                                "课：" +
                                _vm._s(item.title)
                            ),
                          ]),
                          _c("div", { staticStyle: { flex: "1" } }),
                          _c(
                            "el-button",
                            {
                              staticClass: "task-rightcurr task-cursor",
                              attrs: { disabled: _vm.btndisable, type: "text" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.addTask(item.id)
                                },
                              },
                            },
                            [_vm._v("新增任务 ")]
                          ),
                          _vm.CourseInfo.status === "未发布" ||
                          _vm.CourseInfo.courseGroup === "培训"
                            ? _c("el-button", {
                                staticClass:
                                  "el-icon-rank task-rightcurr list-group-item",
                                staticStyle: { "font-size": "16px" },
                                attrs: {
                                  disabled: _vm.btndisable,
                                  type: "text",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              staticClass: "task-rightcurr task-cursor",
                              attrs: { disabled: _vm.btndisable, type: "text" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.editLesson(item)
                                },
                              },
                            },
                            [_vm._v("编 辑")]
                          ),
                          _c("div", { staticClass: "clearfix" }),
                          _vm.CourseInfo.status === "未发布" ||
                          _vm.CourseInfo.courseGroup === "培训"
                            ? _c(
                                "el-dropdown",
                                {
                                  attrs: {
                                    disabled: _vm.btndisable,
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" 更 多 "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      item.status === "已启用"
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                type: "success",
                                                size: "mini",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.gotoMakeStatus(
                                                    item.id,
                                                    1,
                                                    item.title
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("取消发布 ")]
                                          )
                                        : _vm._e(),
                                      item.status === "已禁用"
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                type: "warning",
                                                size: "mini",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.publishCourseUnit(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("发布 ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          staticClass: "task-cursor",
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.delLesson(item.id)
                                            },
                                          },
                                        },
                                        [_vm._v("删 除")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            staticStyle: { "padding-left": "20px" },
                            attrs: {
                              reverse: _vm.reverse,
                              tag: "el-timeline",
                              move: _vm.getdata,
                              handle: ".el-icon-rank",
                            },
                            on: { add: _vm.addClass, end: _vm.datadragTaskEnd },
                            model: {
                              value: item.children,
                              callback: function ($$v) {
                                _vm.$set(item, "children", $$v)
                              },
                              expression: "item.children",
                            },
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        _vm._l(item.children, function (activity, index) {
                          return _c(
                            "el-timeline-item",
                            {
                              key: index,
                              attrs: {
                                parentid: activity.courseUnitId,
                                id: activity.id,
                                "hide-timestamp": true,
                                timestamp: activity.createTime,
                              },
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "已发布",
                                    placement: "top",
                                  },
                                },
                                [
                                  activity.status === "已启用"
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-check iconflag",
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "未发布",
                                    placement: "top",
                                  },
                                },
                                [
                                  activity.status === "已禁用"
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-close iconflagred",
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              activity.title.length > 20
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      staticStyle: { "margin-top": "-8px" },
                                      attrs: {
                                        effect: "dark",
                                        content: activity.title,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("font", [
                                        _vm._v(
                                          "任务" +
                                            _vm._s(index + 1) +
                                            "：" +
                                            _vm._s(
                                              activity.title.length > 20
                                                ? activity.title.substring(
                                                    0,
                                                    20
                                                  )
                                                : activity.title
                                            )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _c(
                                    "font",
                                    { staticStyle: { "margin-top": "-8px" } },
                                    [
                                      _vm._v(
                                        " 任务" +
                                          _vm._s(index + 1) +
                                          "：" +
                                          _vm._s(activity.title) +
                                          " "
                                      ),
                                    ]
                                  ),
                              activity.type !== "课件"
                                ? _c("font", { staticClass: "tasktype" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          activity.type === "pdf"
                                            ? "文档"
                                            : activity.type
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              activity.learnPurpose === "Y"
                                ? _c(
                                    "font",
                                    { staticClass: "tasklearnPurpose" },
                                    [_vm._v("学员任务")]
                                  )
                                : _vm._e(),
                              activity.practicePurpose === "Y"
                                ? _c(
                                    "font",
                                    { staticClass: "taskpracticePurpose" },
                                    [_vm._v("课后练习")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-dropdown",
                                {
                                  staticClass: "task-rightcurr",
                                  attrs: {
                                    disabled: _vm.btndisable,
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" 更 多 "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      staticStyle: { padding: "10px" },
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      activity.status === "已启用"
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                type: "success",
                                                size: "mini",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.gotoMakeTaskStatus(
                                                    activity.id,
                                                    1,
                                                    activity.title
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("取消发布 ")]
                                          )
                                        : _vm._e(),
                                      activity.status === "已禁用"
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                type: "warning",
                                                size: "mini",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.publishTask(
                                                    activity.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("发布 ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          staticClass: "task-cursor",
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.delTask(activity.id)
                                            },
                                          },
                                        },
                                        [_vm._v("删 除")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "task-rightcurr",
                                  attrs: {
                                    disabled: _vm.btndisable,
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editBaseTask(activity)
                                    },
                                  },
                                },
                                [_vm._v("编 辑")]
                              ),
                              _c("el-button", {
                                staticClass:
                                  "el-icon-rank task-rightcurr list-group-item",
                                staticStyle: { "font-size": "16px" },
                                attrs: {
                                  disabled: _vm.btndisable,
                                  type: "text",
                                },
                              }),
                              _vm.CourseInfo.courseGroup !== "培训"
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "task-rightcurr",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.giveLessonBook(
                                            activity,
                                            index,
                                            item.children
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("授课资料 ")]
                                  )
                                : _vm._e(),
                              activity.type !== "课件"
                                ? _c(
                                    "el-dropdown",
                                    {
                                      staticClass: "task-rightcurr",
                                      attrs: { trigger: "click" },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "15px",
                                          },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(" 任务详情 "),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                disabled: _vm.btndisable,
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.editTask(activity)
                                                },
                                              },
                                            },
                                            [_vm._v("编 辑")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              staticClass: "task-cursor",
                                              attrs: { type: "text" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.LookTask(
                                                    activity,
                                                    activity.id,
                                                    activity.type
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("预 览 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("span", {
                                staticStyle: {
                                  height: "1px",
                                  "background-color": "#e6e6e6",
                                  width: "100%",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "el-drawer",
            {
              attrs: { visible: _vm.drawer, size: "40%", "with-header": false },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "15px",
                    display: "flex",
                    "flex-direction": "column",
                    width: "100%",
                    overflow: "auto",
                  },
                  style: { height: _vm.autoHeight + "px" },
                },
                [
                  _c("div", { staticStyle: { "font-weight": "bold" } }, [
                    _c("span", [_vm._v("授课资料")]),
                  ]),
                  _c("div", {
                    staticStyle: {
                      width: "100%",
                      height: "1px",
                      "background-color": "#e3e3e3",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("教案")]
                  ),
                  !_vm.btndisable
                    ? _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 10,
                          placeholder: "请输入教案内容",
                        },
                        model: {
                          value: _vm.currentTaskInfo.teacherPlan,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentTaskInfo, "teacherPlan", $$v)
                          },
                          expression: "currentTaskInfo.teacherPlan",
                        },
                      })
                    : _c("span", {
                        staticStyle: { "font-size": "14px" },
                        domProps: {
                          innerHTML: _vm._s(_vm.currentTaskInfo.teacherPlan),
                        },
                      }),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "15px" },
                          attrs: {
                            disabled: _vm.btndisable,
                            type: "primary",
                            size: "mini",
                          },
                          on: { click: _vm.teachPlan },
                        },
                        [_vm._v("保存教案")]
                      ),
                      _c("hr"),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bold",
                        "margin-top": "10px",
                      },
                    },
                    [_vm._v("课件")]
                  ),
                  _vm.datadrawListOne.length === 0
                    ? _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(" 暂无数据"),
                        _c("br"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("温馨提示：")]
                        ),
                        _c("span", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v("课件类型只可选择一种，一经选择不可更改。"),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.datadrawListOne.length === 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                disabled: _vm.btndisable,
                                type: "primary",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.uploadFileLocal(1)
                                },
                              },
                            },
                            [_vm._v("添加本地课件")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                disabled: _vm.btndisable,
                                type: "primary",
                                size: "mini",
                              },
                              on: { click: _vm.addCoureseItemParen },
                            },
                            [_vm._v("添加在线课件")]
                          ),
                          _c("hr"),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "tb-list mt-15",
                              staticStyle: {
                                width: "100%",
                                "margin-bottom": "20px",
                              },
                              attrs: { data: _vm.datadrawListOne, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "标题",
                                  align: "center",
                                  prop: "title",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "line-height": "40px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { float: "none" },
                                              },
                                              [_vm._v(_vm._s(scope.row.title))]
                                            ),
                                            _c(
                                              "el-popover",
                                              {
                                                staticStyle: { float: "none" },
                                                attrs: {
                                                  placement: "bottom",
                                                  width: "400",
                                                  trigger: "click",
                                                },
                                                model: {
                                                  value:
                                                    _vm.coursewareTitleVsible,
                                                  callback: function ($$v) {
                                                    _vm.coursewareTitleVsible =
                                                      $$v
                                                  },
                                                  expression:
                                                    "coursewareTitleVsible",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-row",
                                                  {
                                                    staticStyle: {
                                                      "line-height": "30px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "right",
                                                        },
                                                        attrs: { span: 8 },
                                                      },
                                                      [_vm._v("课件名称：")]
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            size: "small",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.coursewareTitle,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.coursewareTitle =
                                                                $$v
                                                            },
                                                            expression:
                                                              "coursewareTitle",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-row",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "10px",
                                                      "text-align": "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "small",
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeCourseItem(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("提 交")]
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "small",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            ;(_vm.coursewareTitleVsible = false),
                                                              (_vm.coursewareTitle = false)
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("取 消")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      float: "none",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-edit",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "创建日期", align: "center" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.createTime.substring(
                                                  0,
                                                  11
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  prop: "score",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.btndisable,
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editCourseWare(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编 辑")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewCourseWare(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("预 览 ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.btndisable,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteCourseWare(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删 除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bold",
                        "margin-top": "10px",
                      },
                    },
                    [_vm._v("说课视频")]
                  ),
                  _vm.datadrawListTwo.length === 0
                    ? _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("暂无数据"),
                      ])
                    : _vm._e(),
                  _vm.datadrawListTwo.length === 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                disabled: _vm.btndisable,
                                type: "primary",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.uploadFile(2)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c("hr"),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "tb-list mt-15",
                              staticStyle: {
                                width: "100%",
                                "margin-bottom": "20px",
                              },
                              attrs: { data: _vm.datadrawListTwo, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "标题",
                                  align: "center",
                                  prop: "title",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "创建日期", align: "center" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.createTime.substring(
                                                  0,
                                                  11
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "互动视频", align: "center" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              class:
                                                scope.row.interactiveModel ===
                                                "Y"
                                                  ? "green"
                                                  : "red",
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.interactiveModel ===
                                                    "Y"
                                                    ? "开"
                                                    : "关"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  prop: "score",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addInteractive(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("互动视频")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.btndisable,
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editUpload(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("修 改")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewCourseWare(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("预 览 ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.btndisable,
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteCourseWare(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删 除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bold",
                        "margin-top": "10px",
                      },
                    },
                    [_vm._v("串讲视频")]
                  ),
                  _vm.datadrawListThree.length === 0
                    ? _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("暂无数据"),
                      ])
                    : _vm._e(),
                  _vm.datadrawListThree.length === 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                disabled: _vm.btndisable,
                                type: "primary",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.uploadFile(3)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c("hr"),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "tb-list mt-15",
                              staticStyle: {
                                width: "100%",
                                "margin-bottom": "20px",
                              },
                              attrs: {
                                data: _vm.datadrawListThree,
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "标题",
                                  align: "center",
                                  prop: "title",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "创建日期", align: "center" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.createTime.substring(
                                                  0,
                                                  11
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  prop: "score",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.btndisable,
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editUpload(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("修 改")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewCourseWare(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("预 览 ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.btndisable,
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteCourseWare(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删 除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]
          ),
          _c("el-backtop", {
            attrs: {
              bottom: 40,
              "visibility-height": 50,
              target: ".task-listcurr",
            },
          }),
        ],
        1
      ),
      _c("add-lesson", {
        attrs: {
          show: _vm.lessonDialog,
          CourseInfo: _vm.CourseInfo,
          editLessonData: _vm.editLessonData,
        },
        on: {
          close: function ($event) {
            _vm.lessonDialog = false
            _vm.editLessonData = null
          },
          updateView: _vm.updateView,
        },
      }),
      _c("add-task", {
        attrs: {
          show: _vm.taskDialog,
          lessonId: _vm.lessonId,
          editTaskData: _vm.editTaskData,
          CourseInfo: _vm.CourseInfo,
        },
        on: {
          close: function ($event) {
            _vm.taskDialog = false
            _vm.editTaskData = null
          },
          updateView: _vm.updateView,
        },
      }),
      _c("add-base-task", {
        attrs: {
          show: _vm.baseTaskDialog,
          CourseInfo: _vm.CourseInfo,
          editTaskData: _vm.editTaskData,
          courseUnitId: _vm.courseUnitId,
        },
        on: {
          close: function ($event) {
            _vm.baseTaskDialog = false
          },
          updateView: _vm.updateView,
        },
      }),
      _c("missed-lesson", {
        attrs: {
          applyCourseData: _vm.applyCourseData,
          show: _vm.applyCourseFlag,
        },
        on: {
          close: function ($event) {
            _vm.applyCourseFlag = false
          },
        },
      }),
      _c("add-courseware", {
        attrs: {
          addMissCourseData: _vm.addMissCourseData,
          show: _vm.dialogaddCourseWare,
        },
        on: {
          close: function ($event) {
            _vm.dialogaddCourseWare = false
          },
        },
      }),
      _c("upload-givelesson-book", {
        attrs: {
          editFlag: _vm.editFlag,
          show: _vm.uploadDialog,
          type: _vm.waretype,
          editUploadData: _vm.editUploadData,
          taskId: _vm.currentTaskInfo.id,
        },
        on: {
          updateView: _vm.updateWareData,
          close: function ($event) {
            _vm.uploadDialog = false
          },
        },
      }),
      _c("copycur", {
        attrs: { show: _vm.copycurFlag },
        on: {
          close: function ($event) {
            _vm.copycurFlag = false
          },
        },
      }),
      _c("interactive-video", {
        attrs: {
          attachMentId: _vm.attachMentId,
          taskList: _vm.thembTasklist,
          show: _vm.interactiveDialog,
        },
        on: {
          updateView: _vm.updateWareData,
          close: function ($event) {
            _vm.interactiveDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }