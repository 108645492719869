<template>
  <el-row>
    <h3 class="tab-title">基本信息</h3>
    <!-- formData：{{formData}} -->
    <!-- CourseInfo：{{CourseInfo}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <!-- <el-form-item label="所属分组：">
        <el-input v-model="formData.groupCourse" disabled class="tab-input"></el-input>
      </el-form-item> -->
      <el-form-item label="所属分组：" prop="courseGroup">
        <el-select
          v-model="formData.courseGroup"
          placeholder="请选择所属分组"
          @change="courseGroupChange"
        >
          <el-option
            v-for="item in courseGroupList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="所属分类：">
        <el-select v-model="formData.type" placeholder="请选择所属分类">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="所属分类：" prop="catalogId">
        <el-select
          v-model="formData.catalogId"
          placeholder="请选择所属分类"
        >
          <el-option
            v-for="item in catalogIdList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程名称：" prop="title">
        <el-input :disabled="CourseInfo.isCopy === 'Y'" v-model="formData.title" placeholder="请输入课程名称（输入课程名称不可重复）" class="tab-input"></el-input>
      </el-form-item>
      <el-form-item label="课程昵称：">
        <el-input :disabled="CourseInfo.isCopy === 'Y'" v-model="formData.nick" placeholder="请输入课程昵称（显示给购买课程的学员的名称）" class="tab-input"></el-input>
      </el-form-item>
      <el-form-item label="课程类型：">
        <el-select v-model="formData.type"   placeholder="请选择课程类型">
          <el-option
            v-for="item in (formData.courseGroup == '培训' ? typeLists :typeList)"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="课时类型：">
        <el-input v-model="formData.courseType" disabled class="tab-input"></el-input>
      </el-form-item> -->
      <el-form-item label="课程账号类型：" prop="series" v-if="formData.courseGroup === '编程'">
        <el-select
          v-model="formData.series"
          placeholder="请选择课程账号类型"
          @change="handleSelectChange"
        >
          <el-option
            v-for="item in cascaderList"
            :key="item.id"
            :label="item.courseGoodsName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-button size="medium" type="primary" style="margin: 35px;" @click="editCurriculum" v-has="'curriculum:ManageEdit'">保存</el-button>
  </el-row>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute curriculumID 课程id
 * @attribute cascaderList 级联选择器所需数据
 * @attribute CourseInfo  课程基本信息
 */
import { editCourse ,courseCatalog,$getCatalogTree} from '@/api/research/curriculum'
// import { listKnowledgePoint } from '@/api/research/knowledge'

export default {
  props: {
    curriculumID: {
      default: '',
      type: String
    },
    CourseInfo: {
      default: () => {
        return {}
      },
      type: Object
    },
    CourseInfo1: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    CourseInfo:  {
      handler(val){
        this.formData = { ...val}
        if(val.courseGoods){
          this.formData.series = val.courseGoods.id
        }
        if(val.courseGroup){
          this.getCatalogTree()
        }
      },
      immediate:true,
      deep:true,
    },
  },

  data () {
    return {
      // knowledgeList:[],
      formData: {
        series:''
      },
      rules: {
        title: [
          { required: true, message: '课程名称不能为空', trigger: 'blur' },
          { max: 50, message: '字数限制在50个字内', trigger: 'blur' }
        ],
        catalogId: [{ required: true, message: '类别ID不能为空', trigger: 'change' }]
      },
      cascaderList: [],
      typeList: [
        {
          value: "常规",
          label: "常规",
        },
        {
          value: "双师",
          label: "双师",
        },
        {
          value: "视频",
          label: "视频",
        },
        {
          value: "试听",
          label: "试听",
        }
      ],
      typeLists: [
        {
          value: "培训",
          label: "培训",
        },
      ],
      courseGroupList: [
        {
          value: "编程",
          label: "编程",
        },
        {
          value: "培训",
          label: "培训",
        },
      ],
      catalogIdList:[],
    }
  },

  created() {
    this.courseCatalog();
  },
  methods: {
    courseGroupChange() {
      this.getCatalogTree()
    },
    handleSelectChange() {
      this.$forceUpdate()
    },
    async getCatalogTree() {
      const res = await $getCatalogTree({
        group:this.formData.courseGroup
      });
      this.catalogIdList = res.body;
    },
    async courseCatalog() {
      const res = await courseCatalog({
        pageNum: 1,
        pageSize: 1000,
        needCount: true,
      });
      this.cascaderList = res.body.list;
    },
    editCurriculum () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          editCourse({
            id: this.formData.id,
            courseGoodsId: this.formData.series,
            catalogId:this.formData.catalogId,
            courseGroup:this.formData.courseGroup,
            title: this.CourseInfo.title === this.formData.title ? null : this.formData.title,
            nick: this.formData.nick,
            type: this.formData.type,
            openClassNum : this.formData.type === '双师' ? this.formData.openClassNum : null
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('编辑成功')
              this.$emit('updatBaseInfo')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-title {
  padding-left: 35px;
  margin: 20px 0;
  font-size: 16px;
  color: #464646;
  font-weight: 600
}
.tab-input {
  width: 350px;
}
/deep/ .el-form-item {
  margin-bottom: 20px;
}
</style>
