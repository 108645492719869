<template>
  <el-row>
    <!-- editTaskData: {{editTaskData}} -->
    <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
      <el-row>
        <el-form-item label="任务标题：" prop="title">
          <el-input v-model="formData.title" placeholder="请输入任务标题" maxlength="32"></el-input>
        </el-form-item>
      </el-row>
      <!-- dataList:{{dataList}} -->
      <el-row>
        <el-form-item label="任务题库：" prop="bank">
          <span
            style="display:inline-block; margin-right: 25px;cursor: pointer;"
            @click="addQuesBank"
          >
            <i class="el-icon-circle-plus"></i>
            新建题库
          </span>
          <span style="cursor: pointer;" @click="seleQuesBank">
            <i class="el-icon-s-operation"></i>
            选择题库
          </span>
          <el-table
            style="border-top: 1px solid #EBEEF5;border-left: 1px solid #EBEEF5;margin-top: 25px;width: 70%;"
            :data="dataList"
            border
            fit
            highlight-current-row
          >
            <el-table-column align="center" label="已选题库">
              <template slot-scope="scope">
                <span class="title-link" @click="editQuesBank(scope.row)">{{ scope.row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <!-- <el-button type="text" @click="editQuesBank(scope.row)">编辑</el-button> -->
                <el-button type="text" @click="delQuesBank(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-row>
      <el-form-item label="完成条件：">
        <span>完成所有已开放的编程题</span>
      </el-form-item>

      <el-form-item label="题解视频：">
        <el-switch
          v-model="formData.videoSwitch"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开"
          inactive-text="关">
        </el-switch>
        <span class="inline-block ml-15">(设置题解视频是否对学员开放)</span>
      </el-form-item>
    </el-form>

    <!-- add -->
    <add-topic
      :show="addTopicDialog"
      :editData="editData"
      :isActivity="true"
      @close="addTopicDialog=false,editData=null"
      @getQuesibraryOne="getQuesibraryOne"
    ></add-topic>

    <!-- sele -->
    <sele-topic
      :show="seleTopicDialog"
      @close="seleTopicDialog=false"
      @saveTopic="saveTopic"
    ></sele-topic>
  </el-row>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import AddTopic from '@/components/research/quesbank-manage/addTopic'
import SeleTopic from '@/components/planning/activity-detail/manage-topic'
import { addTask, editTask } from '@/api/research/curriculum'
import { getQuesibraryList } from '@/api/research/quesBank'

export default {
  props: {
    editTaskData: {
      default: () => {
        return null
      },
      type: Object
    },
    lessonId: {
      default: '',
      type: String
    },
    taskTitle: {
      default: '',
      type: String
    },
    CourseInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  components: { AddTopic,Tinymce,SeleTopic },
  watch: {
    editTaskData: {
      handler (val) {
        // 数据同步
        this.formData.seleTopic = []
        if (this.editTaskData !== null && this.editTaskData.sourceId !== null) {
          this.formData.title = this.editTaskData.title
          this.getQuesibraryOne(this.editTaskData.sourceId)
        }
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    let RichBankVal = (rule, value, callback) => {
      if (this.dataList.length === 0) {
        callback(new Error('请选择题库'))
      } else {
        callback()
      }
    }
    return {
      formData: {
        isShow: 1,
        title: '',
        seleTopic: [],
        teacherPlan:'',
        lecturePurpose:false,
        learnPurpose:false,
        practicePurpose:false,
      },
      dataList: [],
      contest_id: '',
      rules: {
        title: [{ required: true, message: '请输入题目名称', trigger: 'blur' }],
        bank: [
          { required: true, validator: RichBankVal, trigger: 'blur' }
        ]
      },
      addTopicDialog: false,
      editData: null,
      seleTopicDialog: false
    }
  },
  methods: {
    updateViewData() {
      this.$refs.tinyjiaoan.setContent(this.formData.teacherPlan)
    },
    addQuesBank () {
      this.addTopicDialog = true
    },

    seleQuesBank () {
      this.seleTopicDialog = true
    },

    editQuesBank (row) {
      this.editData = row
      this.addTopicDialog = true
    },

    delQuesBank (row) {
      this.dataList.splice(0, 1)
    },

    async getQuesibraryOne (contest_id) {
      const res = await getQuesibraryList({
        contest_id: contest_id
      })
      this.dataList = res.body.list
      console.log('dataList', this.dataList)
    },

    saveTopic (data) {
      this.dataList = Object.assign([], data)
    },

    addTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          addTask({
            type: '编程',
            courseUnitId: this.lessonId,
            title: this.formData.title,
            completeCondition: '完成所有已开放的编程题',
            sourceContent: this.dataList[0].contest_id,
            access: Number(this.dataList[0].census_control) === 1 ? '公开' : '私有',
            videoSwitch: this.formData.videoSwitch,
            teacherPlan:this.formData.teacherPlan,
            lecturePurpose:this.formData.lecturePurpose ? 'Y' : 'N',
            learnPurpose:this.formData.learnPurpose ? 'Y' : 'N',
            practicePurpose:this.formData.practicePurpose ? 'Y' : 'N',
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('添加成功')
              this.$emit('updateTask',res)
            }
          })
        }
      })
    },

    editTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          editTask({
            id: this.editTaskData.id,
            title: this.formData.title, // 标题
            sourceId: this.dataList[0].contest_id,
            access: Number(this.dataList[0].census_control) === 1 ? '公开' : '私有',
            videoSwitch: this.formData.videoSwitch,
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('编辑成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/dialog.scss";
</style>
