var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            "label-width": "120px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "任务标题：", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入任务标题", maxlength: "32" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "任务题库：", prop: "bank" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "margin-right": "25px",
                        cursor: "pointer",
                      },
                      on: { click: _vm.addQuesBank },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus" }),
                      _vm._v(" 新建题库 "),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.seleQuesBank },
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-operation" }),
                      _vm._v(" 选择题库 "),
                    ]
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        "border-top": "1px solid #EBEEF5",
                        "border-left": "1px solid #EBEEF5",
                        "margin-top": "25px",
                        width: "70%",
                      },
                      attrs: {
                        data: _vm.dataList,
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", label: "已选题库" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "title-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editQuesBank(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.title))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delQuesBank(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "完成条件：" } }, [
            _c("span", [_vm._v("完成所有已开放的编程题")]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "题解视频：" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "开",
                  "inactive-text": "关",
                },
                model: {
                  value: _vm.formData.videoSwitch,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "videoSwitch", $$v)
                  },
                  expression: "formData.videoSwitch",
                },
              }),
              _c("span", { staticClass: "inline-block ml-15" }, [
                _vm._v("(设置题解视频是否对学员开放)"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-topic", {
        attrs: {
          show: _vm.addTopicDialog,
          editData: _vm.editData,
          isActivity: true,
        },
        on: {
          close: function ($event) {
            ;(_vm.addTopicDialog = false), (_vm.editData = null)
          },
          getQuesibraryOne: _vm.getQuesibraryOne,
        },
      }),
      _c("sele-topic", {
        attrs: { show: _vm.seleTopicDialog },
        on: {
          close: function ($event) {
            _vm.seleTopicDialog = false
          },
          saveTopic: _vm.saveTopic,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }