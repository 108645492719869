<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="任务链接：" prop="sourceContent">
      <el-input v-model.trim="formData.sourceContent" placeholder="请填写http/https开头的链接"></el-input>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition">
      至少观看&nbsp;
      <el-input-number v-model="formData.completeCondition" :min="1"></el-input-number>&nbsp;分钟
    </el-form-item>
    <el-form-item label="默认开放：" prop="defOpen">
      <el-switch
        v-model="formData.defOpen"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-text="开"
        inactive-text="关">
      </el-switch>
      <span class="inline-block ml-15">(设置学员任务默认是否开放)</span>
    </el-form-item>
  </el-form>
</template>

<script>
import { addTask, editTask } from '@/api/research/curriculum'

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    let RichLinkVal = (rule, value, callback) => {
      let reg = /^http(s)?/
      if (!reg.test(value)) {
        callback(new Error('请填写正确的外链地址'))
      } else {
        callback()
      }
    }
    return {
      formData: {
        title: '',
        sourceContent: '',
        completeCondition: 1,
        defOpen: false
      },
      rules: {
        title: [{ required: true, message: '请填写任务标题', trigger: 'blur' }],
        sourceContent: [
          { required: true, validator: RichLinkVal, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    addTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          addTask({
            type: '外链',
            courseUnitId: this.lessonId,
            title: this.formData.title,
            completeCondition: this.formData.completeCondition,
            sourceContent: this.formData.sourceContent,
            defOpen: this.formData.defOpen
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('添加成功')
              this.$emit('updateTask',res)
            }
          })
        }
      })
    },

    editTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          editTask({
            id: this.editTaskData.id,
            title: this.formData.title, // 标题
            sourceContent: this.formData.sourceContent, // 资源内容
            completeCondition: this.formData.completeCondition, // 任务完成条件
            defOpen: this.formData.defOpen
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('编辑成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    }
  }
}
</script>
