<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    width="70%"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>选择题库</span>
    </div>

    <el-row :gutter="20">
      <el-col :span="8">
        <el-input placeholder="请输入试卷名称" v-model.trim="queryData.inputVal" @keyup.enter.native="search">
          <el-select v-model="queryData.select" slot="prepend" placeholder="请选择" style="width: 120px;">
            <el-option label="题库标题" value="1"></el-option>
            <el-option label="题库ID" value="2"></el-option>
          </el-select>
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-col>
      <el-col :span="8">
        <el-row>
          <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
          <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
        </el-row>
      </el-col>
    </el-row>

    <el-table
      style="border-top: 1px solid #EBEEF5;border-left: 1px solid #EBEEF5;margin-top: 25px;"
      :data="tableData"
      border
      fit
      highlight-current-row
      ref="tableData"
      class="paper-table"
      @selection-change="handleSelectionChange"
      @current-change="handleCurrent"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="题库ID" width="200" align="center" prop="contest_id"></el-table-column>
      <el-table-column label="题库标题" align="left" prop="title"></el-table-column>
      <el-table-column label="包含问题" width="200" align="center" prop="problemNum"></el-table-column>
      <!-- <el-table-column label="状态" width="200" align="center">
        <template slot-scope="scope">
          <span v-text="getState(scope.row.defunct)" :style="'color'+':'+getStateColor(scope.row.defunct)"></span>
        </template>
      </el-table-column> -->
    </el-table>

    <el-row>
      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-row>

    <!-- footer -->
    <el-row slot="footer" style="text-align: left;">
      <el-button type="primary" @click="saveTopic()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getQuesibraryList } from '@/api/research/quesBank'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      queryData: {
        inputVal: '', // 检索文本
        select: '1', // 检索条件
      },
      tableData: [],
      seleData: null, // 选中数据
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  created () {},
  methods: {
    openDialog () {
      this.seleData = null
      this.getQuesibraryList()
    },

    // 获取试题详细信息
    async getQuesibraryList () {
      this.tableData = []
      const res = await getQuesibraryList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        title: this.queryData.select === '1' ? this.queryData.inputVal : '',
        contest_id: this.queryData.select === '2' ? this.queryData.inputVal : '',
        defunct: 'N'
      })
      this.tableData = res.body.list
      this.pagination.total = res.body.total
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 选择题目
    seleTaskPaper () {},

    handleSelectionChange (val) {
      this.seleData = val
      if (val.length > 1) {
        this.$refs.tableData.clearSelection()
        this.$refs.tableData.toggleRowSelection(val.pop())
      }
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getQuesibraryList()
    },

    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getQuesibraryList()
    },

    // 任意位置勾选
    handleCurrent (val) {
      this.$refs.tableData.toggleRowSelection(val)
    },

    // 搜索
    search () {
      this.pagination.currentPage = 1
      this.getQuesibraryList()
    },

    // 重置
    reset () {
      this.queryData.inputVal = ''
      this.queryData.select = '1'
    },

    // 添加/保存试卷
    saveTopic () {
      if (!this.seleData) {
        window.$msg('无勾选数据', 2)
      } else {
        this.$emit('saveTopic', this.seleData)
        this.close()
      }
    },

    // getState (state) {
    //   switch (state) {
    //     case 'Y':
    //       return '已停用'
    //     case 'N':
    //       return '已启用'
    //     default:
    //       return '未知状态'
    //   }
    // },
    // getStateColor (state) {
    //   switch (state) {
    //     case 'Y':
    //       return '#CE433F'
    //     case 'N':
    //       return '#4DA54D'
    //     default:
    //       return '#F5C319'
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
