<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition">
      至少观看&nbsp;
      <el-input-number v-model="formData.completeCondition" :min="1"></el-input-number>&nbsp;分钟
    </el-form-item>
  </el-form>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import { addTask, editTask } from '@/api/research/curriculum'

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { Tinymce },

  methods: {
    updateViewData() {
      this.$nextTick(()=> {
        this.$refs.tinymceref.setContent(this.formData.teacherPlan)
      })
    },
  },
  data () {
    let RichTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('请填写任务内容'))
      } else {
        callback()
      }
    }
    return {
      formData: {
        title: '',
        completeCondition: 1,
        teacherPlan:'',
        lecturePurpose:false,
        learnPurpose:false,
        practicePurpose:false,
      },
      rules: {
        title: [{ required: true, message: '请填写任务标题', trigger: 'blur' }],
      },
      addTask () {
        this.$refs['formData'].validate(valid => {
          if (valid) {
            console.log(JSON.stringify(this.formData))
            addTask({
              type: '课件',
              courseUnitId: this.lessonId,
              title: this.formData.title,
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('添加成功')
                this.$emit('updateTask',res)
              }
            })
          }
        })
      },

      editTask () {
        this.$refs['formData'].validate(valid => {
          if (valid) {
            editTask({
              id: this.editTaskData.id,
              title: this.formData.title, // 标题
              completeCondition: this.formData.completeCondition, // 任务完成条件
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.$emit('updateTask')
              }
            })
          }
        })
      }
    }
  }
}
</script>
