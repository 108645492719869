<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <div v-for="(item,index) in topicList"  style="display: flex;align-items: center;width: 100%">
      <div>
        <el-form-item label="任务内容：">
          <Tinymce ref="tinymceref" :key="item.id" v-model="item.description" width="100%" :height="350"/>
        </el-form-item>
        <el-form-item label="关联模板：">
          <el-select
            style="width:250px;"
            filterable
            v-model="item.content"
            placeholder="请关联和任务相关的模板"
          >
            <el-option v-for="value in moduleArray" :key="value.id" :label="value.title+'  ['+value.creatorName+']'"
                       :value="value.id">

            </el-option>
          </el-select>
          <el-button style="margin-left: 30px;" type="primary" size="medium" @click="add" class="tb-button">新增模板</el-button>
        </el-form-item>
      </div>
      <i class="el-icon-circle-plus-outline iconStyle" @click="plusClick" v-if="index === 0" ></i>
      <i class="el-icon-remove-outline iconStyle" @click="removeClick(index)" v-else ></i>
    </div>
    <el-form-item label="完成条件：" prop="completeCondition">
      <span>完成上传作品</span>
    </el-form-item>
    <add-scratch-modle
      :show="addMoudleDialog"
      @close="addMoudleDialog=false"
      @scratchmoduleList="scratchmoduleList"
    ></add-scratch-modle>
  </el-form>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import {getTaskTopicList, editTask} from '@/api/research/curriculum'
import {scratchmoduleList} from '@/api/research/scratch'
import AddScratchModle from '@/components/research/quesbank-manage/addScratchModle'

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {Tinymce, AddScratchModle},
  mounted() {
    this.scratchmoduleList("")
  },
  methods: {
    removeClick(index) {
      this.topicList.splice(index,1)
    },
    plusClick() {
      this.topicList.push(
        {
          id:Math.round(Math.random()*100000),
          batch:null,
          description:'',
          content:''
        }
      )
    },
    async getTaskTopicList() {
      const res = await getTaskTopicList({
        taskId:this.editTaskData.id
      })
      this.topicList = res.body

      if(this.topicList.length === 0) {
        this.topicList =  [{
          id:Math.round(Math.random()*100000),
          batch:null,
          description:'',
          content:''
        }]
        this.$forceUpdate()
      }else {
        this.topicList.forEach(item => {
          item.id = Math.round(Math.random()*100000)
        })
      }
    },
    updateViewData() {
      this.topicList.forEach((item,index) => {
        this.$refs.tinymceref[index].setContent(item.description)
      })
    },

    async scratchmoduleList(data) {
      const response = await scratchmoduleList({
        pageNum: 1,
        pageSize: 10000,
        type: 'scratch'
      })
      this.moduleArray = response.body.list
    },
    add() {
      this.addMoudleDialog = true
    },
  },
  data() {
    let RichTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('请填写任务内容'))
      } else {
        callback()
      }
    }
    return {
      topicList: [{
        id:Math.round(Math.random()*100000),
        batch:null,
        description:'',
        content:''
      }],
      moduleArray: [],
      addMoudleDialog: false,
      formData: {
        type: '',
        description: '',
        title: '',
        sourceContent: '',
        sourceId: "",
        completeCondition: 1,
        teacherPlan: '',
        lecturePurpose: false,
        learnPurpose: false,
        practicePurpose: false,
      },
      rules: {
        title: [{required: true, message: '请填写任务标题', trigger: 'blur'}],
        description: [{required: true, validator: RichTextVal, trigger: 'blur'}],
        sourceId: [
          {required: true, message: "请关联模板", trigger: 'blur'}
        ]
      },

      editTask() {
        this.$refs['formData'].validate(valid => {
          if (valid) {
            let contentFlag = false
            this.topicList.forEach(item => {
              if(item.content === ''){
                contentFlag = true
              }
            })

            if(contentFlag) {
              window.$msg('请先选择任务模板',2)
              return false
            }
            editTask({
              id: this.editTaskData.id,
              title: this.formData.title, // 标题
              taskItems:this.topicList,
              completeCondition: "完成上传作品", // 任务完成条件
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.$emit('updateTask')
              }
            })
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.iconStyle {
  margin-left: 15px;
  font-size: 24px;
}
</style>
