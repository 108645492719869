var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.lessonTitle) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "任务标题：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "90%" },
                attrs: { placeholder: "请填写课节名称" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "任务模式：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "90%" },
                  attrs: {
                    disabled: _vm.editTaskData != null ? true : false,
                    placeholder: "请选择任务模式",
                  },
                  on: { change: _vm.typeChange },
                  model: {
                    value: _vm.formData.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type",
                  },
                },
                _vm._l(_vm.typeList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.formData.type !== "课件" && _vm.CourseInfo.courseGroup !== "培训"
            ? _c(
                "el-form-item",
                { attrs: { prop: "learnPurpose" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("学员任务： "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "设置为学员任务后可将任务推送给学生端查看",
                            placement: "top",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-warning-outline",
                            staticStyle: { "font-weight": "bold" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("el-switch", {
                    attrs: {
                      disabled:
                        _vm.formData.type === "视频" &&
                        _vm.CourseInfo.type === "视频",
                      "active-text": "是",
                      "inactive-text": "否",
                    },
                    model: {
                      value: _vm.formData.learnPurpose,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "learnPurpose", $$v)
                      },
                      expression: "formData.learnPurpose",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.formData.type !== "课件" && _vm.formData.learnPurpose
            ? _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("课后练习： "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "设置为课后练习的任务可以作为学员课后练习使用",
                            placement: "top",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-warning-outline",
                            staticStyle: { "font-weight": "bold" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("el-switch", {
                    attrs: { "active-text": "是", "inactive-text": "否" },
                    model: {
                      value: _vm.formData.practicePurpose,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "practicePurpose", $$v)
                      },
                      expression: "formData.practicePurpose",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveLesson()
                },
              },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }