<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="任务内容：" prop="description">
      <Tinymce ref="tinymceref" v-model="formData.description" width="100%" :key="formData.id" :height="350"/>
    </el-form-item>
    <el-form-item label="关联模板：" prop="sourceId" v-if="formData.type !== ''">
      <el-select
        style="width:250px;"
        filterable
        v-model="formData.sourceId"
        placeholder="请关联和任务相关的模板"
        @visible-change="()=>scratchmoduleList('')"
      >
        <el-option v-for="item in moduleArray" :key="item.id" :label="item.title+'  ['+item.name+']'"
                   :value="item.id">

        </el-option>
      </el-select>
      <el-button style="margin-left: 30px;" type="primary" size="medium" @click="add" class="tb-button">新增模板</el-button>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition">
      <span>完成上传作品</span>
    </el-form-item>
    <el-form-item>
      <span slot="label">默认开放：</span>
      <el-switch
        v-model="formData.defOpen"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-text="开"
        inactive-text="关">
      </el-switch>
    </el-form-item>
    <el-form-item prop="learnPurpose">
         <span slot="label">学员任务：
          <el-tooltip class="item" content="标识此任务是否在学生端可见" effect="dark" placement="top">
            <i class="el-icon-warning-outline" style="font-weight: bold;"></i>
          </el-tooltip>
        </span>
      <el-switch
        v-model="formData.learnPurpose"
        active-text="是"
        inactive-text="否">
      </el-switch>
    </el-form-item>
    <el-form-item prop="practicePurpose">
         <span slot="label">课后练习：
          <el-tooltip class="item" content="标识此任务是否可以作为课堂练习在加盟学生端使用" effect="dark" placement="top">
            <i class="el-icon-warning-outline" style="font-weight: bold;"></i>
          </el-tooltip>
        </span>
      <el-switch
        v-model="formData.practicePurpose"
        active-text="是"
        inactive-text="否">
      </el-switch>
    </el-form-item>
    <!-- add -->
    <add-scratch-modle
      :show="addMoudleDialog"
      :title='title'
      @close="addMoudleDialog=false"
      @scratchmoduleList="scratchmoduleList"
    ></add-scratch-modle>
  </el-form>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import {addTask, editTask} from '@/api/research/curriculum'
import {scratchmoduleList} from '@/api/research/scratch'
import AddScratchModle from '@/components/research/quesbank-manage/addScratchModle'
import { editWorkInfo, getWorksList,saveProject } from "@/api/works/index";

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {Tinymce, AddScratchModle},
  methods: {
    handleSelectChange() {
      this.scratchmoduleList("")
    },
    updateViewData() {
      this.scratchmoduleList("")
      this.$refs.tinymceref.setContent(this.formData.description === null ? '' : this.formData.description)
      this.formData.learnPurpose = this.editTaskData.learnPurpose == "Y" ? true: false
      this.formData.lecturePurpose = this.editTaskData.lecturePurpose == "Y" ? true : false
      this.formData.practicePurpose = this.editTaskData.practicePurpose == "Y" ? true : false
    },
    async scratchmoduleList(data) {
      const response = await getWorksList({
        pageNum: 1,
        pageSize: 10000,
        needCount: true,
        type: 'scratchjr'
      })
      this.moduleArray = response.body.list
      if (data !== "") {
        this.formData.sourceId = data
      }
    },
    add() {
      let routeUrl = this.$router.resolve({
        path:'/ScratchJr/moulds',
      })
      window.open(routeUrl.href, '_blank')
      // this.addMoudleDialog = true
    },
  },
  data() {
    let RichTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('请填写任务内容'))
      } else {
        callback()
      }
    }
    return {
      title: '新增模板',
      scratchOptions: [
        {
          value: 'scratchjr',
          label: 'scratchjr',
        }, {
          value: 'scratch',
          label: 'scratch',
        }
      ],
      moduleArray: [],
      addMoudleDialog: false,
      formData: {
        type: '',
        description: '',
        title: '',
        sourceContent: '',
        sourceId: "",
        completeCondition: 1,
        learnPurpose: false,
      },
      rules: {
        title: [{required: true, message: '请填写任务标题', trigger: 'blur'}],
        description: [{required: true, validator: RichTextVal, trigger: 'blur'}],
        sourceId: [
          {required: true, message: "请关联模板", trigger: 'blur'}
        ],
        type: [
          {required: true, message: "请选择任务类型", trigger: 'change'}
        ]
      },

      addTask() {
        this.$refs['formData'].validate(valid => {
          if (valid) {
            if (this.formData.sourceId === "") {
              $msg("请选择关联此环节的模板", 2)
              return false
            }
            addTask({
              type: this.formData.type,
              courseUnitId: this.lessonId,
              title: this.formData.title,
              completeCondition: "完成上传作品",
              description: this.formData.description,
              sourceContent: this.formData.sourceId,
              learnPurpose: this.formData.learnPurpose ? 'Y' : 'N',// 是否为学员任务
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('添加成功')
                this.$emit('updateTask')
              }
            })
          }
        })
      },

      editTask() {
        this.$refs['formData'].validate(valid => {
          if (valid) {
            if (this.formData.sourceId.length === 0) {
              $msg("请选择关联此环节的模板", 2)
              return false
            }
            editTask({
              id: this.editTaskData.id,
              title: this.formData.title, // 标题
              sourceId: this.formData.sourceId,
              sourceContent: this.formData.sourceId,
              description: this.formData.description,// 环节描述
              completeCondition: "完成上传作品", // 环节完成条件
              defOpen: this.formData.defOpen,
              practicePurpose:this.formData.practicePurpose ? 'Y' : 'N',// 是否为学员课练习
              learnPurpose: this.formData.learnPurpose ? 'Y' : 'N',// 是否为学员任务
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.$emit('updateTask')
              }
            })
          }

        })
      }
    }
  }
}
</script>
