<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>复制课程</span>
    </div>
    <div style="display: flex">
      <div>
        <span>当前课程下的课节</span>
        <el-table
          :data="tableData"
          @selection-change="handleSelectionChange"
          style="width: 100%;margin-top: 10px;">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="title" label="课节名称" width="180"></el-table-column>
        </el-table>
        <el-row slot="footer">
          <el-row style="text-align: left;margin-bottom: 15px;margin-top: 20px;">
            <el-button type="primary" size="small" @click="saveTag">保 存</el-button>
            <el-button  type="info" size="small" @click="close">取 消</el-button>
          </el-row>
        </el-row>
      </div>
      <div style="margin-left: 32px">
        <span>选择要复制到的课程</span>
        <div>
           <el-cascader
             style="width: 100%;margin-top: 10px"
             clearable
             ref="cascader"
             placeholder="请选择要复制到的课程"
             :show-all-levels="false"
             v-model="courseId"
             :options="tableArr"
             :props="{value: 'id', label: 'name'}"
           >
             <template slot-scope="{ node, data }">
                <span v-if="data.type === undefined"><i class="el-icon-folder folderClass"></i></span>
                <span v-else><i class="el-icon-tickets ticketsClass"></i></span>
                <span>{{ data.name }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
             </template>
           </el-cascader>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {getCourseUnitListOnly} from '@/api/senate/class'
import {cloneCourseUnitToAnotherCourse} from '@/api/research/curriculum'
import getCourseType from '@/mixins/getCouresTypeLess'


export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
  },
  mixins: [getCourseType],
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      courseId:[],
    }
  },
  methods: {
    openDialog() {
      this.tableData = []
      this.multipleSelection = []
      this.courseId = []
      console.log(JSON.stringify(this.tableArr))
      this.getCourseUnitListOnly()
    },

    // 关闭
    close() {
      this.$emit('close')
    },
    async cloneCourseUnitToAnotherCourse() {
      if(this.multipleSelection.length === 0) {
        window.$msg('请选择要复制的课节',2)
        return false
      }else if(this.courseId.length === 0) {
        window.$msg('请选择要复制课节到哪个课程',2)
        return false
      }
      let courseUnitIdsThemb = []
      this.multipleSelection.forEach(item => {
        courseUnitIdsThemb.push(item.id)
      })
      const res1 = await cloneCourseUnitToAnotherCourse({
        courseId: this.courseId [this.courseId.length - 1],
        courseUnitIds: courseUnitIdsThemb,
      })
      if(res1.state === 'success') {
        window.$msg('课节复制成功')
        this.close()
      }
    },
    async getCourseUnitListOnly() {
      const res1 = await getCourseUnitListOnly({
        pageNum: 1,
        pageSize: 10000,
        courseId: this.$route.query.curriculumID,
        status: '已启用',
      })
      this.tableData = res1.body.list
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    saveTag() {
      this.cloneCourseUnitToAnotherCourse()
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
