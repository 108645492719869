var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "任务上传：", prop: "sourceContent" } },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "margin-top": "10px" } },
            [
              _c(
                "el-upload",
                {
                  ref: "uploadpdf",
                  staticClass: "upload-demo",
                  attrs: {
                    "auto-upload": true,
                    "before-remove": _vm.beforeRemove,
                    "file-list": _vm.fileList,
                    "on-change": _vm.onFileChange,
                    "before-upload": _vm.beforeAvatarUpload,
                    "on-success": _vm.handleAvatarSuccess,
                    accept: ".pdf,.docx,.doc,.ppt,.pptx,.xls,.xlsx",
                    action: _vm.uploadUrl,
                    name: "file",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitUpload()
                        },
                      },
                    },
                    [_vm._v("上传到服务器")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("文件名不能包含中文，上传文件不能超过 200MB!")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-form-item",
        { attrs: { label: "完成条件：", prop: "completeCondition" } },
        [
          _vm._v(" 至少观看  "),
          _c("el-input-number", {
            attrs: { min: 1 },
            model: {
              value: _vm.formData.completeCondition,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "completeCondition", $$v)
              },
              expression: "formData.completeCondition",
            },
          }),
          _vm._v(" 分钟 "),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("span", { attrs: { slot: "label" }, slot: "label" }, [
            _vm._v("默认开放："),
          ]),
          _c("el-switch", {
            attrs: {
              "active-color": "#13ce66",
              "inactive-color": "#ff4949",
              "active-text": "开",
              "inactive-text": "关",
            },
            model: {
              value: _vm.formData.defOpen,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "defOpen", $$v)
              },
              expression: "formData.defOpen",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }