<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false">
    <div slot="title"
         class="dia-tit">
      <i></i>
      <span v-text="lessonTitle"></span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="任务标题：" prop="title">
        <el-input style='width:90%' v-model="formData.title" placeholder="请填写课节名称"></el-input>
      </el-form-item>
      <el-form-item label="任务模式：" prop="type">
        <el-select  style='width:90%' @change="typeChange" v-model="formData.type" :disabled="editTaskData != null ? true : false" placeholder="请选择任务模式">
          <el-option
            v-for="(item, index) in typeList"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="learnPurpose" v-if="formData.type !== '课件' && CourseInfo.courseGroup !== '培训'">
         <span slot="label">学员任务：
          <el-tooltip class="item" effect="dark" content="设置为学员任务后可将任务推送给学生端查看" placement="top">
            <i class="el-icon-warning-outline" style="font-weight: bold;"></i>
          </el-tooltip>
        </span>
        <el-switch
          :disabled="(formData.type === '视频') && CourseInfo.type === '视频'"
          v-model="formData.learnPurpose"
          active-text="是"
          inactive-text="否">
        </el-switch>
      </el-form-item>
      <el-form-item v-if="formData.type !== '课件' && formData.learnPurpose">
        <span slot="label">课后练习：
          <el-tooltip class="item" effect="dark" content="设置为课后练习的任务可以作为学员课后练习使用" placement="top">
            <i class="el-icon-warning-outline" style="font-weight: bold;"></i>
          </el-tooltip>
        </span>
        <el-switch
          v-model="formData.practicePurpose"
          active-text="是"
          inactive-text="否">
        </el-switch>
      </el-form-item>
    </el-form>
    <!-- footer -->
    <el-row slot="footer">
      <el-button type="primary" @click="saveLesson()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editLessonData 根据有无 editLessonData 动态渲染 dialog弹出框 数据
 * @function close 自定义弹窗关闭事件
 */
import { addTask, editTask } from '@/api/research/curriculum'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    courseUnitId:{
      default: '',
      type: String
    },
    CourseInfo: {
      default: () => {
        return {}
      },
      type: Object
    },
    editTaskData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show() {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },

  data() {
    return {
      typeList:[],
      normalList:[
        {
          label:'仅授课资料（教案、课件、课堂实录）',
          value:'课件'
        },{
          label:'授课资料+图文',
          value:'图文'
        },{
          label:'授课资料+外链',
          value:'外链'
        },{
          label:'授课资料+视频',
          value:'视频'
        },{
          label:'授课资料+试卷',
          value:'测试'
        },{
          label:'授课资料+文档',
          value:'pdf'
        },
        {
          label:'授课资料+ScratchJr编程练习',
          value:'scratchjr'
        },{
          label:'授课资料+Scratch编程练习',
          value:'scratch'
        },{
          label:'授课资料+Python编程练习',
          value:'python'
        },{
          label:'授课资料+Pygame游戏练习',
          value:'Pygame'
        },{
          label:'授课资料+Oj编程练习',
          value:'编程'
        }
      ],
      trainList:[
        {
          label:'PPT',
          value:'ppt'
        },
        {
          label:'图文',
          value:'图文'
        },{
          label:'视频',
          value:'视频'
        },{
          label:'文档',
          value:'pdf'
        },{
          label:'试卷',
          value:'测试'
        },{
          label:'外链',
          value:'外链'
        },{
          label:'Python',
          value:'python'
        },{
          label:'Pygame',
          value:'Pygame'
        },{
          label:'Scratch',
          value:'scratch'
        },{
          label:'Oj编程',
          value:'编程'
        }
      ],
      curriculumID: '', // 课程id
      lessonTitle: '',
      formData: {},
      rules: {
        title: [{required: true, message: '请填写课名称', trigger: 'blur'}],
        type: [{required: true, message: '请选择任务模式', trigger: 'change'}],
      },
      fileData: {},
      fileList: []
    }
  },
  created() {
    this.curriculumID = this.$route.query.curriculumID
  },
  methods: {
    typeChange() {
      console.log(this.formData.type)
     if(this.formData.type === '视频' || this.formData.type === 'pdf') {
       this.formData.learnPurpose = false
     }
    },
    openDialog() {
      this.formData = {
        learnPurpose:false,
      }
      if(this.CourseInfo.courseGroup === '培训') {
        this.typeList = this.trainList
      }else if(this.CourseInfo.courseGroup === '编程'){
        this.typeList = this.normalList
      }
      if (this.editTaskData !== null) {
        this.lessonTitle = '编辑任务'
        this.formData = {...this.editTaskData}
        this.formData.learnPurpose = this.editTaskData.learnPurpose == "N" ? false : true
        this.formData.lecturePurpose = this.editTaskData.lecturePurpose == "N" ? false : true
        this.formData.practicePurpose = this.editTaskData.practicePurpose == "N" ? false : true
      } else {
        this.lessonTitle = '新增任务'
      }
    },

    // 关闭弹窗
    close() {
      this.$emit('close')
    },

    // 添加课
    saveLesson() {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if(this.editTaskData !== null) {
            editTask({
              id: this.editTaskData.id,
              title: this.formData.title,
              learnPurpose:this.formData.learnPurpose ? 'Y' : 'N',
              practicePurpose:!this.formData.learnPurpose ? 'N' : this.formData.practicePurpose ? 'Y' : 'N',
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.$emit('updateView')
                this.close()
              }
            })
          }else {
            addTask({
              type: this.formData.type,
              courseUnitId: this.courseUnitId,
              title: this.formData.title,
              learnPurpose:this.formData.learnPurpose ? 'Y' : 'N',
              practicePurpose:!this.formData.learnPurpose ? 'N' : this.formData.practicePurpose ? 'Y' : 'N',
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('添加成功')
                this.$emit('updateView')
                this.close()
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
